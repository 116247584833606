import emailMisspelled, { all } from "email-misspelled";
import isNaN from "lodash/isNaN";
import toPath from "lodash/toPath";
import * as z from "zod";
import {
  A2GradeEnum,
  ASGradeEnum,
  BtecGradeEnum,
  ExtendedProjectGradeEnum,
  IBGradeEnum,
  QualificationType,
  QualificationTypeEnum,
} from "./model/enums";
import { EnglishQualification, Message, Path, SecondaryEducationQualifications } from "./model/types";
import { exactType } from "./utils";
import currentYear from "./utils/currentYear";

export const schemaForType =
  <T>() =>
  <S extends z.ZodType<T>>(arg: S) => {
    return arg;
  };

// Taken from escape-string-regexp by Sindre Sorhus, licensed MIT
// https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js
//
// (The escape-string-regexp library is written in ESM Javascript.
// It's easier inlining it here than configuring Jest to load it with transformIgnorePatterns.)
function escapeStringRegexp(str: string): string {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
}

const emailChecker = emailMisspelled({ domains: all });

export function pathToString(pathArray: Path): string | number {
  return pathArray.reduce((string, item) => {
    const prefix = string === "" ? "" : ".";
    return string + (isNaN(Number(item)) ? prefix + item : "[" + item + "]");
  }, "");
}

export const stringToPath = toPath;

export function mapMaybeYupValidationToMessages(error: unknown, level: Message["level"] = "error", pathPrefix?: string[]) {
  if (error instanceof z.ZodError) {
    return mapYupValidationToMessages(error, level, pathPrefix);
  }
  throw error;
}

export const mapYupValidationToMessages = (error: z.ZodError, level: Message["level"] = "error", pathPrefix?: string[]): Message[] => {
  const errors: Message[] = [];
  if (error.issues) {
    error.issues.forEach(stack => {
      errors.push({
        level,
        text: stack.message,
        path: toPath(pathPrefix ? [...pathPrefix, ...stack.path] : stack.path),
      });
    });
    return errors;
  }
  return [];
};

const hasSubject = z.string({ invalid_type_error: "Subject Required" }).nonempty({ message: "Subject required" });

const noSubject = z.null({ invalid_type_error: "Subject Forbidden" }).optional();

const requiredGrade = "Grade is required";

function gradeOneOf(arr: string[], message: string) {
  // FIXME.. use enum later?
  return z
    .string({
      invalid_type_error: requiredGrade,
      required_error: requiredGrade,
    })
    .refine(value => arr.includes(value), {
      message,
    });
}

function gradeSetOf(num: number, arr: string[], message: string) {
  const string = `(\\s*(${arr.map(escapeStringRegexp).join("|")})\\s*){${num}}`;
  const regexp = new RegExp(string);
  return z
    .string({
      invalid_type_error: requiredGrade,
      required_error: requiredGrade,
    })
    .nonempty({ message: requiredGrade })
    .regex(regexp, { message });
}

const toNumberIfString = (check: unknown) => (typeof check === "string" ? Number(check) : check);

export function intGradeBetween(min: number, max: number, message: string = `${min} to ${max}`) {
  return z.preprocess(
    toNumberIfString,
    z.number({ required_error: message, invalid_type_error: message }).int({ message }).gte(min, { message }).lte(max, { message }),
  );
}

export function numericGradeBetween(min: number, max: number, message: string = `${min} to ${max.toFixed(1)}`) {
  return z.preprocess(toNumberIfString, z.number({ invalid_type_error: message }).gte(min, { message }).lte(max, { message }));
}

const now = currentYear();
const year = z
  .number({ invalid_type_error: "Year is required" })
  .int()
  .gte(1900, { message: "Must be 1900 or later" })
  .lte(now, { message: `Must be ${now} or earlier` })
  .nullable();

const qualificationBase = z
  .object({
    yearObtained: year,
    tpe: z
      .string({
        invalid_type_error: "Qualification Required",
        required_error: "Qualification Required",
      })
      .nonempty({ message: "Qualification Required" }),
    subject: z.string().nonempty({ message: "Subject Required" }).nullish(),
    grade: z.string().nonempty({ message: "Grade Required" }).nullish(),
  })
  .partial({
    yearObtained: true,
  })
  .strict();

export const qualificationUnion = z.discriminatedUnion("tpe", [
  qualificationBase.extend({
    tpe: z.literal("A2"),
    subject: hasSubject,
    grade: gradeOneOf(A2GradeEnum.values, "A2 grade"),
  }),
  qualificationBase.extend({
    tpe: z.literal("AS"),
    subject: hasSubject,
    grade: gradeOneOf(ASGradeEnum.values, "AS grade"),
  }),
  qualificationBase.extend({
    tpe: z.literal("IBOverall"),
    subject: noSubject,
    grade: intGradeBetween(0, 45, "0 to 45"),
  }),
  qualificationBase.extend({
    tpe: z.literal("IBHigher"),
    subject: hasSubject,
    grade: gradeOneOf(IBGradeEnum.values, "IB grade"),
  }),
  qualificationBase.extend({
    tpe: z.literal("IBStandard"),
    subject: hasSubject,
    grade: gradeOneOf(IBGradeEnum.values, "IB grade"),
  }),
  qualificationBase.extend({
    tpe: z.literal("BtecExtendedRqf"),
    subject: hasSubject,
    grade: gradeSetOf(3, BtecGradeEnum.values, "BTEC grade x3"),
  }),
  qualificationBase.extend({
    tpe: z.literal("BtecNationalRqf"),
    subject: hasSubject,
    grade: gradeSetOf(2, BtecGradeEnum.values, "BTEC grade x2"),
  }),
  qualificationBase.extend({
    tpe: z.literal("BtecSubsidiaryRqf"),
    subject: hasSubject,
    grade: gradeOneOf(BtecGradeEnum.values, "BTEC grade"),
  }),
  qualificationBase.extend({
    tpe: z.literal("BtecExtendedQcf"),
    subject: hasSubject,
    grade: gradeSetOf(3, BtecGradeEnum.values, "BTEC grade x3"),
  }),
  qualificationBase.extend({
    tpe: z.literal("BtecNationalQcf"),
    subject: hasSubject,
    grade: gradeSetOf(2, BtecGradeEnum.values, "BTEC grade x2"),
  }),
  qualificationBase.extend({
    tpe: z.literal("BtecSubsidiaryQcf"),
    subject: hasSubject,
    grade: gradeOneOf(BtecGradeEnum.values, "BTEC grade"),
  }),
  qualificationBase.extend({
    tpe: z.literal("FrenchBacOverall"),
    subject: noSubject,
    grade: numericGradeBetween(0, 20),
  }),
  qualificationBase.extend({
    tpe: z.literal("FrenchBac"),
    subject: hasSubject,
    grade: numericGradeBetween(0, 20),
  }),
  qualificationBase.extend({
    tpe: z.literal("GermanAbiturOverall"),
    subject: noSubject,
    grade: numericGradeBetween(0, 6),
  }),
  qualificationBase.extend({
    tpe: z.literal("GermanAbitur"),
    subject: hasSubject,
    grade: intGradeBetween(0, 15),
  }),
  qualificationBase.extend({
    tpe: z.literal("IndianHsNatOverall"),
    subject: noSubject,
    grade: intGradeBetween(0, 100),
  }),
  qualificationBase.extend({
    tpe: z.literal("IndianHsWbOverall"),
    subject: noSubject,
    grade: intGradeBetween(0, 100),
  }),
  qualificationBase.extend({
    tpe: z.literal("IndianHsOtherOverall"),
    subject: noSubject,
    grade: intGradeBetween(0, 100),
  }),
  qualificationBase.extend({
    tpe: z.literal("IndianHsNat"),
    subject: hasSubject,
    grade: intGradeBetween(0, 100),
  }),
  qualificationBase.extend({
    tpe: z.literal("IndianHsWb"),
    subject: hasSubject,
    grade: intGradeBetween(0, 100),
  }),
  qualificationBase.extend({
    tpe: z.literal("IndianHsOther"),
    subject: hasSubject,
    grade: intGradeBetween(0, 100),
  }),
  qualificationBase.extend({
    tpe: z.literal("ItalianDiplomaOverall"),
    subject: noSubject,
    grade: intGradeBetween(0, 100),
  }),
  qualificationBase.extend({
    tpe: z.literal("ItalianDiploma"),
    subject: hasSubject,
    grade: numericGradeBetween(0, 10),
  }),
  qualificationBase.extend({
    tpe: z.literal("PolishMatura"),
    subject: hasSubject,
    grade: intGradeBetween(0, 100),
  }),
  qualificationBase.extend({
    tpe: z.literal("SpanishBacOverall"),
    subject: noSubject,
    grade: numericGradeBetween(0, 10),
  }),
  qualificationBase.extend({
    tpe: z.literal("SpanishBac"),
    subject: hasSubject,
    grade: intGradeBetween(0, 10),
  }),
  qualificationBase.extend({
    tpe: z.literal("UsDiplomaOverall"),
    subject: noSubject,
    grade: numericGradeBetween(0, 4),
  }),
  qualificationBase.extend({
    tpe: z.literal("UsDiploma"),
    subject: hasSubject,
    grade: intGradeBetween(0, 5),
  }),
  qualificationBase.extend({
    tpe: z.literal("UCATScore"),
    subject: noSubject,
    grade: intGradeBetween(0, 3600),
  }),
  qualificationBase.extend({
    tpe: z.literal("UCATBand"),
    subject: noSubject,
    grade: intGradeBetween(1, 4),
  }),
  qualificationBase.extend({
    tpe: z.literal("EPQ"),
    subject: noSubject,
    grade: gradeOneOf(ExtendedProjectGradeEnum.values, "EPQ grade"),
  }),
]);

type ZodQualification = z.infer<typeof qualificationUnion>;
declare let qa: ZodQualification["tpe"];
declare let qb: QualificationType;
// eslint-disable-next-line no-constant-condition
if (false) {
  exactType(qa, qb);
}

export const qualification = qualificationBase.superRefine((value, ctx) => {
  if (QualificationTypeEnum.isValue(value.tpe)) {
    const result = qualificationUnion.safeParse(value);
    if (result.success) {
      return true;
    }

    result.error.issues.forEach(ctx.addIssue);
  }

  return true;
});

export function qualificationHasSubject(tpe: string) {
  if (QualificationTypeEnum.isValue(tpe)) {
    const v = qualificationUnion.optionsMap.get(tpe);
    if (v) {
      return !v.shape.subject.isNullable();
    }
  }
  // Other:
  return true;
}

const version = z.number().int().gte(0);

const qualificationList = z
  .array(qualification, {
    description: "Qualification List",
    invalid_type_error: "Minimum of one qualification required",
    required_error: "Minimum of one qualification required",
  })
  .nonempty({ message: "Minimum of one qualification required" });

export const emailAddress = z
  .string({
    description: "E-mail",
    invalid_type_error: "E-mail is required",
  })
  .email()
  .min(1, { message: "E-mail is required" });

/* some 'may be' invalid checks */
export const emailAddressStrict = emailAddress.refine(
  value => {
    if (!value) {
      return false;
    }
    const result = emailChecker(value);
    if (result.length) {
      return false;
    }
    return true;
  },
  value => ({
    message: value === null ? `Email is required` : `Your email may have been misspelled. Check it carefully`,
  }),
);

export const ucasId = z
  .string({ description: "UCAS ID", invalid_type_error: "UCAS ID is required" })
  .regex(/^\d{10,10}$/, { message: "Your UCAS ID should be a 10 digit number" });

const validIELTSScore = z.number({ invalid_type_error: "Score is Required" }).min(0).max(9);

const validTOEFLScore = z.number({ invalid_type_error: "Score is Required" }).min(0).max(30);

const validTOEFLScoreOverall = z.number({ invalid_type_error: "Score is Required" }).min(0).max(120);

const ieltsOptions = z.object({
  overall: validIELTSScore,
  listening: validIELTSScore,
  reading: validIELTSScore,
  writing: validIELTSScore,
  speaking: validIELTSScore,
});

const toeflOptions = z.object({
  overall: validTOEFLScoreOverall,
  listening: validTOEFLScore,
  reading: validTOEFLScore,
  writing: validTOEFLScore,
  speaking: validTOEFLScore,
});

export const qualificationEnglish = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("Gcse"),
    grade: z
      .string({
        invalid_type_error: "Grade is Required",
        required_error: "Grade is Required",
      })
      .min(1),
  }),
  z.object({ type: z.literal("Ielts") }).merge(ieltsOptions),
  z
    .object({
      type: z.literal("Toefl"),
    })
    .merge(toeflOptions),
  z.object({
    type: z.literal("Other"),
  }),
]);

type ZodEnglishQualification = z.infer<typeof qualificationEnglish>;
declare let a: EnglishQualification["type"];
declare let b: ZodEnglishQualification["type"];
// eslint-disable-next-line no-constant-condition
if (false) {
  exactType(a, b);
}

const gcse = z.object({
  has5orMoreAtC4OrAbove: z.boolean(),
  hasMathsAtB5OrAbove: z.boolean(),
  has6orMoreAt6and7: z.boolean(),
});

export const secondaryEducation = z.discriminatedUnion(
  "type",
  [
    z
      .object({
        type: z.literal("Gcse"),
      })
      .merge(gcse),
    z.object({
      type: z.literal("Other"),
    }),
  ],
  {
    description: "Secondary Education",
    invalid_type_error: "Secondary Education Required",
  },
);

type ZodSecondaryEducation = z.infer<typeof secondaryEducation>;
declare let sea: SecondaryEducationQualifications["type"];
declare let seb: ZodSecondaryEducation["type"];

// eslint-disable-next-line no-constant-condition
if (false) {
  exactType(sea, seb);
}

export const qualifications = z
  .object(
    {
      list: qualificationList,
      english: z.nullable(qualificationEnglish),
      secondaryEducation,
      version,
    },
    {
      description: "Qualifications",
    },
  )
  .partial({
    english: true,
  })
  .strict();
