import { ProgrammeCode, Qualification } from "../model/types";
import { programmeCodeToUcasCourseCode } from "@qmspringboard/shared/dist/model/programmeCode";
import { QualificationTypeEnum } from "../model/enums";
import { z } from "zod";

// FMD is not usually in Clearing, but if it is some courses require an additional
// qualification called a UCAT.

const ucatCourseCodes: string[] = ["A100", "A101", "A200"];

export const isUcatCourseCode = (programmeCode?: ProgrammeCode | null) =>
  programmeCode ? ucatCourseCodes.includes(programmeCodeToUcasCourseCode(programmeCode)) : false;

// There are two UCAT elements we collect.

// 1) UCAT Score
// UCAT is made up of 4 cognitive tests, each with a score from 300-900.
// The sum of these is collected from a portal applicant and we call it the UCAT Score.

// This function to validate the qualifications is present with a grade (ie entered fully)
const isUcatScore = (q: Qualification) => q.tpe === QualificationTypeEnum.UCATScore && q.grade;

// There is a minimum entry requirement.
const minUcatScoreEntryRequirement = 2720; // or higher

// 2) UCAT Band
// The Situational Judgement score is also 300-900, but these are graded as "Bands".
// Band 1 is the highest (best) and 4 is the lowest)

const isUcatBand = (q: Qualification) => q.tpe === QualificationTypeEnum.UCATBand && q.grade;

const minUcatBandEntryRequirement = 3; // or lower

// NB: The GradeEditor enforces these min and max values in the UI.

// We require score and band if an applicant applies to one of the UCAT programmes.
// We add the qualifications into the qualificaitons form if the user hasn't done so themselves.

export const hasAllUCATQualification = (qualifications: Qualification[]): boolean =>
  qualifications.some(isUcatScore) && qualifications.some(isUcatBand);

export const hasAnyUCATQualification = (qualifications: Qualification[]): boolean =>
  qualifications.some(isUcatScore) || qualifications.some(isUcatBand);

const isUcatQualification = (q: Qualification): boolean => q.tpe === QualificationTypeEnum.UCATBand || q.tpe === QualificationTypeEnum.UCATScore;

// Keep the qualifications which are non-UCAT, or if they are UCAT, keep the ones with a grade:
export const removeEmptyUCATFromQualifications = (qualifications: Qualification[]): Qualification[] => {
  return qualifications.filter(q => !isUcatQualification(q) || q.grade);
};

// We valudate these qualifications against the entry requirements on form submission (client-side).

const qualificationSchema = z.object({
  tpe: z.string(),
  grade: z.string().nullish(),
});

export const ucatQualificationsParser = z.array(qualificationSchema).superRefine((qs, ctx) => {
  const bandCond = qs.some(q => q.tpe === QualificationTypeEnum.UCATBand && q.grade && parseInt(q.grade) <= minUcatBandEntryRequirement);
  const scoreCond = qs.some(q => q.tpe === QualificationTypeEnum.UCATScore && q.grade && parseInt(q.grade) >= minUcatScoreEntryRequirement);

  if (!bandCond) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `UCAT Qualification Band is required for this programme at Band ${minUcatBandEntryRequirement} or above.`,
    });
  }

  if (!scoreCond) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `UCAT Qualification is required for this programme at ${minUcatScoreEntryRequirement} or above.`,
    });
  }
});
