import { A2Grade, BtecGrade, IBGrade, isA2Grade, isBtecGrade, isIBGrade, Subject } from "./qualifications.generated";
import {
  Condition,
  EnglishRequirement,
  OverallRequirement,
  RequiredSubjectAtGrade,
  RequiredSubjectNoGrade,
  Requirements,
  RequirementsAlternative,
} from "./requirements.generated";
export * from "./requirements.generated";

export type LiteralOrConditionalALevelGrades = OverallRequirement;

export function splitGrades<A extends string>(grades: string, validGrade: (v: string) => v is A): A[] {
  const splitGrades: A[] = [];
  let startIndex = 0;
  let checkLen = grades.length;
  // find largest match that passes validity test

  while (startIndex < checkLen) {
    const check = grades.slice(startIndex, checkLen);
    if (validGrade(check)) {
      splitGrades.push(check);
      startIndex = startIndex + check.length;
      checkLen = grades.length;
    } else {
      checkLen--;
    }
  }

  return splitGrades;
}

export function splitA2Grades(grades: string): A2Grade[] {
  return splitGrades(grades.toUpperCase(), isA2Grade);
}

export function splitIBGrades(grades: string): IBGrade[] {
  return splitGrades(grades, isIBGrade);
}

export function splitBtecGrades(grades: string): BtecGrade[] {
  return splitGrades(grades.toUpperCase(), isBtecGrade);
}

export const DEFAULT_SUBJECT: Subject = "";
export const DEFAULT_A2GRADE: A2Grade = "A";
export const DEFAULT_IBGRADE: IBGrade = "6";
export const DEFAULT_BTEC: BtecGrade = "D";

export const DEFAULT_REQUIRED_SUBJECT_NO_GRADE: RequiredSubjectNoGrade = {
  type: "RequiredSubjectNoGrade",
  subject: DEFAULT_SUBJECT,
};

export const DEFAULT_REQUIRED_SUBJECT_AT_GRADE: RequiredSubjectAtGrade = {
  type: "RequiredSubjectAtGrade",
  a2Grade: DEFAULT_A2GRADE,
  ibGrade: DEFAULT_IBGRADE,
  subject: DEFAULT_SUBJECT,
};

export const DEFAULT_CONDITION: Condition = {
  type: "Condition",
  numberRequired: 1,
  subjects: [DEFAULT_REQUIRED_SUBJECT_NO_GRADE],
};

export const DEFAULT_OVERALL: OverallRequirement = {
  a2Grades: [DEFAULT_A2GRADE, DEFAULT_A2GRADE, DEFAULT_A2GRADE],
  ibGrades: [],
  btecGrades: [],
  ibPoints: 0,
  aLevelEquivalence: "Strict",
};

export const DEFAULT_REQUIREMENTS_CASE: RequirementsAlternative = {
  type: "RequirementsAlternative",
  overall: DEFAULT_OVERALL,
  conditions: [],
};

export const validEnglishRequirement: EnglishRequirement = {
  gcse: "1",
  frenchBac: 100,
  ielts: {
    overall: 6,
    listening: 6,
    reading: 6,
    writing: 6,
    speaking: 6,
  },
  indianHs: 70,
  toefl: {
    overall: 25,
    listening: 30,
    reading: 20,
    writing: 20,
    speaking: 20,
  },
};
export const DEFAULT_REQUIREMENTS: Requirements = {
  type: "Requirements",
  alternatives: [DEFAULT_REQUIREMENTS_CASE],
  contextualAlternatives: [],
  english: validEnglishRequirement,
  maths: false,
  extendedGCSEs: false,
};
