// ---------------------------
// AUTO-GENERATED. DO NOT EAT.
// ---------------------------

export interface SimpleDropdownOption<A> {
  value: A;
  label: string;
}

export type SimpleDropdownOptions<A> = SimpleDropdownOption<A>[];

export interface EnumEntry<A> {
  value: A;
  label: string;
}

export interface Enum<A> {
  name: string;
  entries: Array<EnumEntry<A>>;
  values: Array<A>;
  dropdownOptions(showValues?: boolean): SimpleDropdownOptions<A>;
  dropdownOptionsWithBlank(showValues?: boolean, blankLabel?: string): SimpleDropdownOptions<A | null>;
  isValue(value: unknown): value is A;
  labelOf(value: unknown): string | null;
}

export type ApplicationChoice = "0" | "1" | "2" | "3" | "4" | "5" | "7";

interface ApplicationChoiceEnum extends Enum<ApplicationChoice> {
  Clearing: ApplicationChoice;
  First: ApplicationChoice;
  Second: ApplicationChoice;
  Third: ApplicationChoice;
  Fourth: ApplicationChoice;
  Fifth: ApplicationChoice;
  Extra: ApplicationChoice;
}

export const ApplicationChoiceEnum: ApplicationChoiceEnum = {
  name: "ApplicationChoice",

  Clearing: "0",
  First: "1",
  Second: "2",
  Third: "3",
  Fourth: "4",
  Fifth: "5",
  Extra: "7",

  entries: [
    { value: "0", label: "Clearing" },
    { value: "1", label: "UCAS First" },
    { value: "2", label: "UCAS Second" },
    { value: "3", label: "UCAS Third" },
    { value: "4", label: "UCAS Fourth" },
    { value: "5", label: "UCAS Fifth" },
    { value: "7", label: "UCAS Extra" },
  ],

  values: ["0", "1", "2", "3", "4", "5", "7"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ApplicationChoice> {
    return ApplicationChoiceEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ApplicationChoice | null> {
    return [
      { value: null, label: blankLabel },
      ...ApplicationChoiceEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ApplicationChoice {
    return (ApplicationChoiceEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ApplicationChoiceEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ApplicationOrigin = "Springboard" | "SITS" | "Portal";

interface ApplicationOriginEnum extends Enum<ApplicationOrigin> {
  Springboard: ApplicationOrigin;
  SITS: ApplicationOrigin;
  Portal: ApplicationOrigin;
}

export const ApplicationOriginEnum: ApplicationOriginEnum = {
  name: "ApplicationOrigin",

  Springboard: "Springboard",
  SITS: "SITS",
  Portal: "Portal",

  entries: [
    { value: "Springboard", label: "Springboard" },
    { value: "SITS", label: "SITS" },
    { value: "Portal", label: "Portal" },
  ],

  values: ["Springboard", "SITS", "Portal"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ApplicationOrigin> {
    return ApplicationOriginEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ApplicationOrigin | null> {
    return [
      { value: null, label: blankLabel },
      ...ApplicationOriginEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ApplicationOrigin {
    return (ApplicationOriginEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ApplicationOriginEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ApplicationMethod = "C" | "U";

interface ApplicationMethodEnum extends Enum<ApplicationMethod> {
  Clearing: ApplicationMethod;
  Ucas: ApplicationMethod;
}

export const ApplicationMethodEnum: ApplicationMethodEnum = {
  name: "ApplicationMethod",

  Clearing: "C",
  Ucas: "U",

  entries: [
    { value: "C", label: "Clearing" },
    { value: "U", label: "UCAS" },
  ],

  values: ["C", "U"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ApplicationMethod> {
    return ApplicationMethodEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ApplicationMethod | null> {
    return [
      { value: null, label: blankLabel },
      ...ApplicationMethodEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ApplicationMethod {
    return (ApplicationMethodEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ApplicationMethodEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type CountryOfDomicile =
  | "XF"
  | "XG"
  | "XH"
  | "XI"
  | "599"
  | "602"
  | "853"
  | "603"
  | "604"
  | "605"
  | "606"
  | "824"
  | "607"
  | "608"
  | "836"
  | "855"
  | "609"
  | "610"
  | "837"
  | "611"
  | "612"
  | "787"
  | "613"
  | "838"
  | "614"
  | "668"
  | "640"
  | "615"
  | "616"
  | "617"
  | "887"
  | "848"
  | "618"
  | "619"
  | "776"
  | "620"
  | "621"
  | "769"
  | "622"
  | "623"
  | "624"
  | "625"
  | "626"
  | "856"
  | "788"
  | "789"
  | "627"
  | "629"
  | "630"
  | "631"
  | "857"
  | "858"
  | "632"
  | "804"
  | "634"
  | "633"
  | "859"
  | "635"
  | "834"
  | "636"
  | "885"
  | "882"
  | "883"
  | "849"
  | "641"
  | "749"
  | "642"
  | "643"
  | "786"
  | "645"
  | "768"
  | "646"
  | "790"
  | "851"
  | "831"
  | "754"
  | "648"
  | "649"
  | "865"
  | "650"
  | "651"
  | "653"
  | "791"
  | "822"
  | "654"
  | "655"
  | "847"
  | "656"
  | "658"
  | "659"
  | "661"
  | "828"
  | "662"
  | "867"
  | "796"
  | "663"
  | "593"
  | "592"
  | "664"
  | "802"
  | "665"
  | "666"
  | "878"
  | "667"
  | "669"
  | "670"
  | "671"
  | "672"
  | "673"
  | "674"
  | "675"
  | "676"
  | "595"
  | "677"
  | "678"
  | "679"
  | "680"
  | "681"
  | "594"
  | "682"
  | "839"
  | "683"
  | "660"
  | "684"
  | "685"
  | "QO"
  | "686"
  | "840"
  | "687"
  | "832"
  | "688"
  | "690"
  | "691"
  | "692"
  | "827"
  | "833"
  | "693"
  | "694"
  | "695"
  | "696"
  | "698"
  | "793"
  | "699"
  | "700"
  | "869"
  | "701"
  | "702"
  | "821"
  | "703"
  | "841"
  | "825"
  | "704"
  | "864"
  | "705"
  | "706"
  | "707"
  | "798"
  | "805"
  | "709"
  | "710"
  | "637"
  | "711"
  | "714"
  | "715"
  | "716"
  | "717"
  | "871"
  | "872"
  | "852"
  | "873"
  | "718"
  | "708"
  | "721"
  | "874"
  | "870"
  | "722"
  | "723"
  | "724"
  | "725"
  | "726"
  | "823"
  | "727"
  | "728"
  | "730"
  | "731"
  | "795"
  | "733"
  | "842"
  | "734"
  | "738"
  | "741"
  | "826"
  | "803"
  | "743"
  | "785"
  | "780"
  | "744"
  | "745"
  | "746"
  | "886"
  | "850"
  | "835"
  | "747"
  | "748"
  | "750"
  | "884"
  | "751"
  | "628"
  | "889"
  | "735"
  | "737"
  | "888"
  | "875"
  | "736"
  | "752"
  | "753"
  | "876"
  | "755"
  | "756"
  | "757"
  | "652"
  | "843"
  | "759"
  | "760"
  | "762"
  | "877"
  | "784"
  | "763"
  | "765"
  | "766"
  | "844"
  | "799"
  | "647"
  | "767"
  | "845"
  | "764"
  | "771"
  | "770"
  | "846"
  | "713"
  | "773"
  | "774"
  | "800"
  | "879"
  | "880"
  | "601"
  | "781"
  | "732";

interface CountryOfDomicileEnum extends Enum<CountryOfDomicile> {
  XF: CountryOfDomicile;
  XG: CountryOfDomicile;
  XH: CountryOfDomicile;
  XI: CountryOfDomicile;
  C599: CountryOfDomicile;
  C602: CountryOfDomicile;
  C853: CountryOfDomicile;
  C603: CountryOfDomicile;
  C604: CountryOfDomicile;
  C605: CountryOfDomicile;
  C606: CountryOfDomicile;
  C824: CountryOfDomicile;
  C607: CountryOfDomicile;
  C608: CountryOfDomicile;
  C836: CountryOfDomicile;
  C855: CountryOfDomicile;
  C609: CountryOfDomicile;
  C610: CountryOfDomicile;
  C837: CountryOfDomicile;
  C611: CountryOfDomicile;
  C612: CountryOfDomicile;
  C787: CountryOfDomicile;
  C613: CountryOfDomicile;
  C838: CountryOfDomicile;
  C614: CountryOfDomicile;
  C668: CountryOfDomicile;
  C640: CountryOfDomicile;
  C615: CountryOfDomicile;
  C616: CountryOfDomicile;
  C617: CountryOfDomicile;
  C887: CountryOfDomicile;
  C848: CountryOfDomicile;
  C618: CountryOfDomicile;
  C619: CountryOfDomicile;
  C776: CountryOfDomicile;
  C620: CountryOfDomicile;
  C621: CountryOfDomicile;
  C769: CountryOfDomicile;
  C622: CountryOfDomicile;
  C623: CountryOfDomicile;
  C624: CountryOfDomicile;
  C625: CountryOfDomicile;
  C626: CountryOfDomicile;
  C856: CountryOfDomicile;
  C788: CountryOfDomicile;
  C789: CountryOfDomicile;
  C627: CountryOfDomicile;
  C629: CountryOfDomicile;
  C630: CountryOfDomicile;
  C631: CountryOfDomicile;
  C857: CountryOfDomicile;
  C858: CountryOfDomicile;
  C632: CountryOfDomicile;
  C804: CountryOfDomicile;
  C634: CountryOfDomicile;
  C633: CountryOfDomicile;
  C859: CountryOfDomicile;
  C635: CountryOfDomicile;
  C834: CountryOfDomicile;
  C636: CountryOfDomicile;
  C885: CountryOfDomicile;
  C882: CountryOfDomicile;
  C883: CountryOfDomicile;
  C849: CountryOfDomicile;
  C641: CountryOfDomicile;
  C749: CountryOfDomicile;
  C642: CountryOfDomicile;
  C643: CountryOfDomicile;
  C786: CountryOfDomicile;
  C645: CountryOfDomicile;
  C768: CountryOfDomicile;
  C646: CountryOfDomicile;
  C790: CountryOfDomicile;
  C851: CountryOfDomicile;
  C831: CountryOfDomicile;
  C754: CountryOfDomicile;
  C648: CountryOfDomicile;
  C649: CountryOfDomicile;
  C865: CountryOfDomicile;
  C650: CountryOfDomicile;
  C651: CountryOfDomicile;
  C653: CountryOfDomicile;
  C791: CountryOfDomicile;
  C822: CountryOfDomicile;
  C654: CountryOfDomicile;
  C655: CountryOfDomicile;
  C847: CountryOfDomicile;
  C656: CountryOfDomicile;
  C658: CountryOfDomicile;
  C659: CountryOfDomicile;
  C661: CountryOfDomicile;
  C828: CountryOfDomicile;
  C662: CountryOfDomicile;
  C867: CountryOfDomicile;
  C796: CountryOfDomicile;
  C663: CountryOfDomicile;
  C593: CountryOfDomicile;
  C592: CountryOfDomicile;
  C664: CountryOfDomicile;
  C802: CountryOfDomicile;
  C665: CountryOfDomicile;
  C666: CountryOfDomicile;
  C878: CountryOfDomicile;
  C667: CountryOfDomicile;
  C669: CountryOfDomicile;
  C670: CountryOfDomicile;
  C671: CountryOfDomicile;
  C672: CountryOfDomicile;
  C673: CountryOfDomicile;
  C674: CountryOfDomicile;
  C675: CountryOfDomicile;
  C676: CountryOfDomicile;
  C595: CountryOfDomicile;
  C677: CountryOfDomicile;
  C678: CountryOfDomicile;
  C679: CountryOfDomicile;
  C680: CountryOfDomicile;
  C681: CountryOfDomicile;
  C594: CountryOfDomicile;
  C682: CountryOfDomicile;
  C839: CountryOfDomicile;
  C683: CountryOfDomicile;
  C660: CountryOfDomicile;
  C684: CountryOfDomicile;
  C685: CountryOfDomicile;
  QO: CountryOfDomicile;
  C686: CountryOfDomicile;
  C840: CountryOfDomicile;
  C687: CountryOfDomicile;
  C832: CountryOfDomicile;
  C688: CountryOfDomicile;
  C690: CountryOfDomicile;
  C691: CountryOfDomicile;
  C692: CountryOfDomicile;
  C827: CountryOfDomicile;
  C833: CountryOfDomicile;
  C693: CountryOfDomicile;
  C694: CountryOfDomicile;
  C695: CountryOfDomicile;
  C696: CountryOfDomicile;
  C698: CountryOfDomicile;
  C793: CountryOfDomicile;
  C699: CountryOfDomicile;
  C700: CountryOfDomicile;
  C869: CountryOfDomicile;
  C701: CountryOfDomicile;
  C702: CountryOfDomicile;
  C821: CountryOfDomicile;
  C703: CountryOfDomicile;
  C841: CountryOfDomicile;
  C825: CountryOfDomicile;
  C704: CountryOfDomicile;
  C864: CountryOfDomicile;
  C705: CountryOfDomicile;
  C706: CountryOfDomicile;
  C707: CountryOfDomicile;
  C798: CountryOfDomicile;
  C805: CountryOfDomicile;
  C709: CountryOfDomicile;
  C710: CountryOfDomicile;
  C637: CountryOfDomicile;
  C711: CountryOfDomicile;
  C714: CountryOfDomicile;
  C715: CountryOfDomicile;
  C716: CountryOfDomicile;
  C717: CountryOfDomicile;
  C871: CountryOfDomicile;
  C872: CountryOfDomicile;
  C852: CountryOfDomicile;
  C873: CountryOfDomicile;
  C718: CountryOfDomicile;
  C708: CountryOfDomicile;
  C721: CountryOfDomicile;
  C874: CountryOfDomicile;
  C870: CountryOfDomicile;
  C722: CountryOfDomicile;
  C723: CountryOfDomicile;
  C724: CountryOfDomicile;
  C725: CountryOfDomicile;
  C726: CountryOfDomicile;
  C823: CountryOfDomicile;
  C727: CountryOfDomicile;
  C728: CountryOfDomicile;
  C730: CountryOfDomicile;
  C731: CountryOfDomicile;
  C795: CountryOfDomicile;
  C733: CountryOfDomicile;
  C842: CountryOfDomicile;
  C734: CountryOfDomicile;
  C738: CountryOfDomicile;
  C741: CountryOfDomicile;
  C826: CountryOfDomicile;
  C803: CountryOfDomicile;
  C743: CountryOfDomicile;
  C785: CountryOfDomicile;
  C780: CountryOfDomicile;
  C744: CountryOfDomicile;
  C745: CountryOfDomicile;
  C746: CountryOfDomicile;
  C886: CountryOfDomicile;
  C850: CountryOfDomicile;
  C835: CountryOfDomicile;
  C747: CountryOfDomicile;
  C748: CountryOfDomicile;
  C750: CountryOfDomicile;
  C884: CountryOfDomicile;
  C751: CountryOfDomicile;
  C628: CountryOfDomicile;
  C889: CountryOfDomicile;
  C735: CountryOfDomicile;
  C737: CountryOfDomicile;
  C888: CountryOfDomicile;
  C875: CountryOfDomicile;
  C736: CountryOfDomicile;
  C752: CountryOfDomicile;
  C753: CountryOfDomicile;
  C876: CountryOfDomicile;
  C755: CountryOfDomicile;
  C756: CountryOfDomicile;
  C757: CountryOfDomicile;
  C652: CountryOfDomicile;
  C843: CountryOfDomicile;
  C759: CountryOfDomicile;
  C760: CountryOfDomicile;
  C762: CountryOfDomicile;
  C877: CountryOfDomicile;
  C784: CountryOfDomicile;
  C763: CountryOfDomicile;
  C765: CountryOfDomicile;
  C766: CountryOfDomicile;
  C844: CountryOfDomicile;
  C799: CountryOfDomicile;
  C647: CountryOfDomicile;
  C767: CountryOfDomicile;
  C845: CountryOfDomicile;
  C764: CountryOfDomicile;
  C771: CountryOfDomicile;
  C770: CountryOfDomicile;
  C846: CountryOfDomicile;
  C713: CountryOfDomicile;
  C773: CountryOfDomicile;
  C774: CountryOfDomicile;
  C800: CountryOfDomicile;
  C879: CountryOfDomicile;
  C880: CountryOfDomicile;
  C601: CountryOfDomicile;
  C781: CountryOfDomicile;
  C732: CountryOfDomicile;
}

export const CountryOfDomicileEnum: CountryOfDomicileEnum = {
  name: "CountryOfDomicile",

  XF: "XF",
  XG: "XG",
  XH: "XH",
  XI: "XI",
  C599: "599",
  C602: "602",
  C853: "853",
  C603: "603",
  C604: "604",
  C605: "605",
  C606: "606",
  C824: "824",
  C607: "607",
  C608: "608",
  C836: "836",
  C855: "855",
  C609: "609",
  C610: "610",
  C837: "837",
  C611: "611",
  C612: "612",
  C787: "787",
  C613: "613",
  C838: "838",
  C614: "614",
  C668: "668",
  C640: "640",
  C615: "615",
  C616: "616",
  C617: "617",
  C887: "887",
  C848: "848",
  C618: "618",
  C619: "619",
  C776: "776",
  C620: "620",
  C621: "621",
  C769: "769",
  C622: "622",
  C623: "623",
  C624: "624",
  C625: "625",
  C626: "626",
  C856: "856",
  C788: "788",
  C789: "789",
  C627: "627",
  C629: "629",
  C630: "630",
  C631: "631",
  C857: "857",
  C858: "858",
  C632: "632",
  C804: "804",
  C634: "634",
  C633: "633",
  C859: "859",
  C635: "635",
  C834: "834",
  C636: "636",
  C885: "885",
  C882: "882",
  C883: "883",
  C849: "849",
  C641: "641",
  C749: "749",
  C642: "642",
  C643: "643",
  C786: "786",
  C645: "645",
  C768: "768",
  C646: "646",
  C790: "790",
  C851: "851",
  C831: "831",
  C754: "754",
  C648: "648",
  C649: "649",
  C865: "865",
  C650: "650",
  C651: "651",
  C653: "653",
  C791: "791",
  C822: "822",
  C654: "654",
  C655: "655",
  C847: "847",
  C656: "656",
  C658: "658",
  C659: "659",
  C661: "661",
  C828: "828",
  C662: "662",
  C867: "867",
  C796: "796",
  C663: "663",
  C593: "593",
  C592: "592",
  C664: "664",
  C802: "802",
  C665: "665",
  C666: "666",
  C878: "878",
  C667: "667",
  C669: "669",
  C670: "670",
  C671: "671",
  C672: "672",
  C673: "673",
  C674: "674",
  C675: "675",
  C676: "676",
  C595: "595",
  C677: "677",
  C678: "678",
  C679: "679",
  C680: "680",
  C681: "681",
  C594: "594",
  C682: "682",
  C839: "839",
  C683: "683",
  C660: "660",
  C684: "684",
  C685: "685",
  QO: "QO",
  C686: "686",
  C840: "840",
  C687: "687",
  C832: "832",
  C688: "688",
  C690: "690",
  C691: "691",
  C692: "692",
  C827: "827",
  C833: "833",
  C693: "693",
  C694: "694",
  C695: "695",
  C696: "696",
  C698: "698",
  C793: "793",
  C699: "699",
  C700: "700",
  C869: "869",
  C701: "701",
  C702: "702",
  C821: "821",
  C703: "703",
  C841: "841",
  C825: "825",
  C704: "704",
  C864: "864",
  C705: "705",
  C706: "706",
  C707: "707",
  C798: "798",
  C805: "805",
  C709: "709",
  C710: "710",
  C637: "637",
  C711: "711",
  C714: "714",
  C715: "715",
  C716: "716",
  C717: "717",
  C871: "871",
  C872: "872",
  C852: "852",
  C873: "873",
  C718: "718",
  C708: "708",
  C721: "721",
  C874: "874",
  C870: "870",
  C722: "722",
  C723: "723",
  C724: "724",
  C725: "725",
  C726: "726",
  C823: "823",
  C727: "727",
  C728: "728",
  C730: "730",
  C731: "731",
  C795: "795",
  C733: "733",
  C842: "842",
  C734: "734",
  C738: "738",
  C741: "741",
  C826: "826",
  C803: "803",
  C743: "743",
  C785: "785",
  C780: "780",
  C744: "744",
  C745: "745",
  C746: "746",
  C886: "886",
  C850: "850",
  C835: "835",
  C747: "747",
  C748: "748",
  C750: "750",
  C884: "884",
  C751: "751",
  C628: "628",
  C889: "889",
  C735: "735",
  C737: "737",
  C888: "888",
  C875: "875",
  C736: "736",
  C752: "752",
  C753: "753",
  C876: "876",
  C755: "755",
  C756: "756",
  C757: "757",
  C652: "652",
  C843: "843",
  C759: "759",
  C760: "760",
  C762: "762",
  C877: "877",
  C784: "784",
  C763: "763",
  C765: "765",
  C766: "766",
  C844: "844",
  C799: "799",
  C647: "647",
  C767: "767",
  C845: "845",
  C764: "764",
  C771: "771",
  C770: "770",
  C846: "846",
  C713: "713",
  C773: "773",
  C774: "774",
  C800: "800",
  C879: "879",
  C880: "880",
  C601: "601",
  C781: "781",
  C732: "732",

  entries: [
    { value: "XF", label: "England" },
    { value: "XG", label: "Northern Ireland" },
    { value: "XH", label: "Scotland" },
    { value: "XI", label: "Wales" },
    { value: "599", label: "United Kingdom not otherwise specified" },
    { value: "602", label: "Afghanistan" },
    { value: "853", label: "Aland Islands" },
    { value: "603", label: "Albania" },
    { value: "604", label: "Algeria" },
    { value: "605", label: "Andorra" },
    { value: "606", label: "Angola" },
    { value: "824", label: "Anguilla" },
    { value: "607", label: "Antigua and Barbuda" },
    { value: "608", label: "Argentina" },
    { value: "836", label: "Armenia" },
    { value: "855", label: "Aruba" },
    { value: "609", label: "Australia" },
    { value: "610", label: "Austria" },
    { value: "837", label: "Azerbaijan" },
    { value: "611", label: "Bahamas" },
    { value: "612", label: "Bahrain" },
    { value: "787", label: "Bangladesh" },
    { value: "613", label: "Barbados" },
    { value: "838", label: "Belarus" },
    { value: "614", label: "Belgium" },
    { value: "668", label: "Belize" },
    { value: "640", label: "Benin" },
    { value: "615", label: "Bermuda" },
    { value: "616", label: "Bhutan" },
    { value: "617", label: "Bolivia" },
    { value: "887", label: "Bonaire" },
    { value: "848", label: "Bosnia and Herzegovina" },
    { value: "618", label: "Botswana" },
    { value: "619", label: "Brazil" },
    { value: "776", label: "British Virgin Islands" },
    { value: "620", label: "Brunei Darussalam" },
    { value: "621", label: "Bulgaria" },
    { value: "769", label: "Burkina Faso" },
    { value: "622", label: "Burma (Myanmar)" },
    { value: "623", label: "Burundi" },
    { value: "624", label: "Cambodia" },
    { value: "625", label: "Cameroon" },
    { value: "626", label: "Canada" },
    { value: "856", label: "Canary Islands" },
    { value: "788", label: "Cape Verde" },
    { value: "789", label: "Cayman Islands" },
    { value: "627", label: "Central African Republic" },
    { value: "629", label: "Chad" },
    { value: "630", label: "Chile" },
    { value: "631", label: "China" },
    { value: "857", label: "Christmas Island" },
    { value: "858", label: "Cocos (Keeling) Islands" },
    { value: "632", label: "Colombia" },
    { value: "804", label: "Comoros" },
    { value: "634", label: "Congo" },
    { value: "633", label: "Congo (Democratic Republic)" },
    { value: "859", label: "Cook Islands" },
    { value: "635", label: "Costa Rica" },
    { value: "834", label: "Croatia" },
    { value: "636", label: "Cuba" },
    { value: "885", label: "Curacao" },
    { value: "882", label: "Cyprus (European Union)" },
    { value: "883", label: "Cyprus (Non-European Union)" },
    { value: "849", label: "Czech Republic" },
    { value: "641", label: "Denmark" },
    { value: "749", label: "Djibouti" },
    { value: "642", label: "Dominica" },
    { value: "643", label: "Dominican Republic" },
    { value: "786", label: "East Timor (Timor-Leste)" },
    { value: "645", label: "Ecuador" },
    { value: "768", label: "Egypt" },
    { value: "646", label: "El Salvador" },
    { value: "790", label: "Equatorial Guinea" },
    { value: "851", label: "Eritrea" },
    { value: "831", label: "Estonia" },
    { value: "754", label: "Eswatini" },
    { value: "648", label: "Ethiopia" },
    { value: "649", label: "Falkland Islands (Malvinas)" },
    { value: "865", label: "Faroe Islands" },
    { value: "650", label: "Fiji" },
    { value: "651", label: "Finland" },
    { value: "653", label: "France" },
    { value: "791", label: "French Guiana" },
    { value: "822", label: "French Polynesia" },
    { value: "654", label: "Gabon" },
    { value: "655", label: "Gambia" },
    { value: "847", label: "Georgia" },
    { value: "656", label: "Germany" },
    { value: "658", label: "Ghana" },
    { value: "659", label: "Gibraltar" },
    { value: "661", label: "Greece" },
    { value: "828", label: "Greenland" },
    { value: "662", label: "Grenada" },
    { value: "867", label: "Guadeloupe" },
    { value: "796", label: "Guam" },
    { value: "663", label: "Guatemala" },
    { value: "593", label: "Guernsey" },
    { value: "592", label: "Guernsey (Alderney)" },
    { value: "664", label: "Guinea" },
    { value: "802", label: "Guinea Bissau" },
    { value: "665", label: "Guyana" },
    { value: "666", label: "Haiti" },
    { value: "878", label: "Holy See (Vatican City State)" },
    { value: "667", label: "Honduras" },
    { value: "669", label: "Hong Kong" },
    { value: "670", label: "Hungary" },
    { value: "671", label: "Iceland" },
    { value: "672", label: "India" },
    { value: "673", label: "Indonesia" },
    { value: "674", label: "Iran" },
    { value: "675", label: "Iraq" },
    { value: "676", label: "Ireland" },
    { value: "595", label: "Isle of Man" },
    { value: "677", label: "Israel" },
    { value: "678", label: "Italy" },
    { value: "679", label: "Ivory Coast (Cote d'Ivoire)" },
    { value: "680", label: "Jamaica" },
    { value: "681", label: "Japan" },
    { value: "594", label: "Jersey" },
    { value: "682", label: "Jordan" },
    { value: "839", label: "Kazakhstan" },
    { value: "683", label: "Kenya" },
    { value: "660", label: "Kiribati" },
    { value: "684", label: "Korea" },
    { value: "685", label: "Korea" },
    { value: "QO", label: "Kosovo" },
    { value: "686", label: "Kuwait" },
    { value: "840", label: "Kyrgyzstan" },
    { value: "687", label: "Lao PDR" },
    { value: "832", label: "Latvia" },
    { value: "688", label: "Lebanon" },
    { value: "690", label: "Lesotho" },
    { value: "691", label: "Liberia" },
    { value: "692", label: "Libya" },
    { value: "827", label: "Liechtenstein" },
    { value: "833", label: "Lithuania" },
    { value: "693", label: "Luxembourg" },
    { value: "694", label: "Macao" },
    { value: "695", label: "Madagascar" },
    { value: "696", label: "Malawi" },
    { value: "698", label: "Malaysia" },
    { value: "793", label: "Maldives" },
    { value: "699", label: "Mali" },
    { value: "700", label: "Malta" },
    { value: "869", label: "Martinique" },
    { value: "701", label: "Mauritania" },
    { value: "702", label: "Mauritius" },
    { value: "821", label: "Mayotte" },
    { value: "703", label: "Mexico" },
    { value: "841", label: "Moldova" },
    { value: "825", label: "Monaco" },
    { value: "704", label: "Mongolia" },
    { value: "864", label: "Montenegro" },
    { value: "705", label: "Montserrat" },
    { value: "706", label: "Morocco" },
    { value: "707", label: "Mozambique" },
    { value: "798", label: "Namibia" },
    { value: "805", label: "Nauru" },
    { value: "709", label: "Nepal" },
    { value: "710", label: "Netherlands" },
    { value: "637", label: "Netherlands Antilles" },
    { value: "711", label: "New Caledonia" },
    { value: "714", label: "New Zealand" },
    { value: "715", label: "Nicaragua" },
    { value: "716", label: "Niger" },
    { value: "717", label: "Nigeria" },
    { value: "871", label: "Niue" },
    { value: "872", label: "Norfolk Island" },
    { value: "852", label: "North Macedonia" },
    { value: "873", label: "Northern Mariana Islands" },
    { value: "718", label: "Norway" },
    { value: "708", label: "Oman" },
    { value: "721", label: "Pakistan" },
    { value: "874", label: "Palau" },
    { value: "870", label: "Palestinian" },
    { value: "722", label: "Panama" },
    { value: "723", label: "Papua New Guinea" },
    { value: "724", label: "Paraguay" },
    { value: "725", label: "Peru" },
    { value: "726", label: "Philippines" },
    { value: "823", label: "Pitcairn Islands" },
    { value: "727", label: "Poland" },
    { value: "728", label: "Portugal" },
    { value: "730", label: "Puerto Rico" },
    { value: "731", label: "Qatar" },
    { value: "795", label: "Reunion" },
    { value: "733", label: "Romania" },
    { value: "842", label: "Russian Federation" },
    { value: "734", label: "Rwanda" },
    { value: "738", label: "Saint Vincent and the Grenadines" },
    { value: "741", label: "Samoa" },
    { value: "826", label: "San Marino" },
    { value: "803", label: "Sao Tome and Principe" },
    { value: "743", label: "Saudi Arabia" },
    { value: "785", label: "Senegal" },
    { value: "780", label: "Serbia" },
    { value: "744", label: "Seychelles" },
    { value: "745", label: "Sierra Leone" },
    { value: "746", label: "Singapore" },
    { value: "886", label: "Sint Maarten (Dutch Part)" },
    { value: "850", label: "Slovakia" },
    { value: "835", label: "Slovenia" },
    { value: "747", label: "Solomon Islands" },
    { value: "748", label: "Somalia" },
    { value: "750", label: "South Africa" },
    { value: "884", label: "South Sudan" },
    { value: "751", label: "Spain" },
    { value: "628", label: "Sri Lanka" },
    { value: "889", label: "St Barthelemy" },
    { value: "735", label: "St Helena" },
    { value: "737", label: "St Lucia" },
    { value: "888", label: "St Martin (French part)" },
    { value: "875", label: "St Pierre And Miquelon" },
    { value: "736", label: "St. Kitts-Nevis" },
    { value: "752", label: "Sudan" },
    { value: "753", label: "Suriname" },
    { value: "876", label: "Svalbard And Jan Mayen" },
    { value: "755", label: "Sweden" },
    { value: "756", label: "Switzerland" },
    { value: "757", label: "Syrian Arab Republic (Syria)" },
    { value: "652", label: "Taiwan" },
    { value: "843", label: "Tajikistan" },
    { value: "759", label: "Tanzania" },
    { value: "760", label: "Thailand" },
    { value: "762", label: "Togo" },
    { value: "877", label: "Tokelau" },
    { value: "784", label: "Tonga" },
    { value: "763", label: "Trinidad and Tobago" },
    { value: "765", label: "Tunisia" },
    { value: "766", label: "Turkey" },
    { value: "844", label: "Turkmenistan" },
    { value: "799", label: "Turks and Caicos Islands" },
    { value: "647", label: "Tuvalu" },
    { value: "767", label: "Uganda" },
    { value: "845", label: "Ukraine" },
    { value: "764", label: "United Arab Emirates" },
    { value: "771", label: "United States of America" },
    { value: "770", label: "Uruguay" },
    { value: "846", label: "Uzbekistan" },
    { value: "713", label: "Vanuatu" },
    { value: "773", label: "Venezuela (Bolivarian Republic of)" },
    { value: "774", label: "Vietnam (Viet Nam)" },
    { value: "800", label: "Virgin Islands (US)" },
    { value: "879", label: "Wallis and Futuna" },
    { value: "880", label: "Western Sahara" },
    { value: "601", label: "Yemen" },
    { value: "781", label: "Zambia" },
    { value: "732", label: "Zimbabwe" },
  ],

  values: [
    "XF",
    "XG",
    "XH",
    "XI",
    "599",
    "602",
    "853",
    "603",
    "604",
    "605",
    "606",
    "824",
    "607",
    "608",
    "836",
    "855",
    "609",
    "610",
    "837",
    "611",
    "612",
    "787",
    "613",
    "838",
    "614",
    "668",
    "640",
    "615",
    "616",
    "617",
    "887",
    "848",
    "618",
    "619",
    "776",
    "620",
    "621",
    "769",
    "622",
    "623",
    "624",
    "625",
    "626",
    "856",
    "788",
    "789",
    "627",
    "629",
    "630",
    "631",
    "857",
    "858",
    "632",
    "804",
    "634",
    "633",
    "859",
    "635",
    "834",
    "636",
    "885",
    "882",
    "883",
    "849",
    "641",
    "749",
    "642",
    "643",
    "786",
    "645",
    "768",
    "646",
    "790",
    "851",
    "831",
    "754",
    "648",
    "649",
    "865",
    "650",
    "651",
    "653",
    "791",
    "822",
    "654",
    "655",
    "847",
    "656",
    "658",
    "659",
    "661",
    "828",
    "662",
    "867",
    "796",
    "663",
    "593",
    "592",
    "664",
    "802",
    "665",
    "666",
    "878",
    "667",
    "669",
    "670",
    "671",
    "672",
    "673",
    "674",
    "675",
    "676",
    "595",
    "677",
    "678",
    "679",
    "680",
    "681",
    "594",
    "682",
    "839",
    "683",
    "660",
    "684",
    "685",
    "QO",
    "686",
    "840",
    "687",
    "832",
    "688",
    "690",
    "691",
    "692",
    "827",
    "833",
    "693",
    "694",
    "695",
    "696",
    "698",
    "793",
    "699",
    "700",
    "869",
    "701",
    "702",
    "821",
    "703",
    "841",
    "825",
    "704",
    "864",
    "705",
    "706",
    "707",
    "798",
    "805",
    "709",
    "710",
    "637",
    "711",
    "714",
    "715",
    "716",
    "717",
    "871",
    "872",
    "852",
    "873",
    "718",
    "708",
    "721",
    "874",
    "870",
    "722",
    "723",
    "724",
    "725",
    "726",
    "823",
    "727",
    "728",
    "730",
    "731",
    "795",
    "733",
    "842",
    "734",
    "738",
    "741",
    "826",
    "803",
    "743",
    "785",
    "780",
    "744",
    "745",
    "746",
    "886",
    "850",
    "835",
    "747",
    "748",
    "750",
    "884",
    "751",
    "628",
    "889",
    "735",
    "737",
    "888",
    "875",
    "736",
    "752",
    "753",
    "876",
    "755",
    "756",
    "757",
    "652",
    "843",
    "759",
    "760",
    "762",
    "877",
    "784",
    "763",
    "765",
    "766",
    "844",
    "799",
    "647",
    "767",
    "845",
    "764",
    "771",
    "770",
    "846",
    "713",
    "773",
    "774",
    "800",
    "879",
    "880",
    "601",
    "781",
    "732",
  ],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<CountryOfDomicile> {
    return CountryOfDomicileEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<CountryOfDomicile | null> {
    return [
      { value: null, label: blankLabel },
      ...CountryOfDomicileEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is CountryOfDomicile {
    return (CountryOfDomicileEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return CountryOfDomicileEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type Nationality =
  | "British Citizen"
  | "British National (Overseas)"
  | "British Overseas Teritories Citizen"
  | "British Overseas Citizen"
  | "British Protected Person"
  | "British Subject"
  | "Yemeni"
  | "Afghan"
  | "Albanian"
  | "Algerian"
  | "Andorran"
  | "Angolan"
  | "Citizen of Antigua and Barbuda"
  | "Argentine"
  | "Australian"
  | "Austrian"
  | "Bahamian"
  | "Bahraini"
  | "Barbadian"
  | "Belgian"
  | "Bhutanese"
  | "Bolivian"
  | "Citizen of Botswana"
  | "Brazilian"
  | "Citizen of Brunei"
  | "Bulgarian"
  | "Citizen of Myanmar"
  | "Citizen of Burundi"
  | "Cambodian"
  | "Cameroonian"
  | "Canadian"
  | "Citizen of the Central African Republic"
  | "Citizen of Sri Lanka"
  | "Chadian"
  | "Chilean"
  | "Chinese"
  | "Colombian"
  | "Citizen of The Democratic Republic of the Congo"
  | "Citizen of the Republic of the Congo"
  | "Costa Rican"
  | "Cuban"
  | "Beninese"
  | "Danish"
  | "Dominican"
  | "Citizen of the Dominican Republic"
  | "Ecuadorian"
  | "Salvadorean"
  | "Tuvaluan"
  | "Ethiopian"
  | "Citizen of Fiji"
  | "Finnish"
  | "Taiwanese"
  | "French"
  | "Gabonese"
  | "Gambian"
  | "German"
  | "Ghanaian"
  | "Citizen of Kiribati"
  | "Greek"
  | "Grenadian"
  | "Guatemalan"
  | "Guinean"
  | "Guyanese"
  | "Haitian"
  | "Honduran"
  | "Citizen of Belize"
  | "Citizen of Hong Kong (Special Administrative Region of China)"
  | "Hungarian"
  | "Icelander"
  | "Indian"
  | "Indonesian"
  | "Iranian"
  | "Iraqi"
  | "Irish Citizen"
  | "Israeli"
  | "Italian"
  | "Citizen of the Ivory Coast"
  | "Jamaican"
  | "Japanese"
  | "Jordanian"
  | "Kenyan"
  | "South Korean"
  | "Citizen of the Democratic People's Republic of Korea"
  | "Kuwaiti"
  | "Lao"
  | "Lebanese"
  | "Citizen of Lesotho"
  | "Liberian"
  | "Libyan"
  | "Luxembourger"
  | "Citizen of Macao (Special Administrative Region of China)"
  | "Citizen of Madagascar"
  | "Malawian"
  | "Citizen of Malaysia"
  | "Malian"
  | "Maltese"
  | "Mauritanian"
  | "Mauritian"
  | "Mexican"
  | "Mongolian"
  | "Moroccan"
  | "Mozambican"
  | "Omani"
  | "Nepalese"
  | "Dutch"
  | "Citizen of Vanuatu"
  | "New Zealander"
  | "Nicaraguan"
  | "Citizen of Niger"
  | "Nigerian"
  | "Norwegian"
  | "Pakistani"
  | "Panamanian"
  | "Papua New Guinean"
  | "Paraguayan"
  | "Peruvian"
  | "Filipino"
  | "Polish"
  | "Portuguese"
  | "Qatari"
  | "Zimbabwean"
  | "Romanian"
  | "Rwandan"
  | "Citizen of St Christopher (St Kitts) and Nevis"
  | "St Lucia"
  | "Vincentian"
  | "Samoan"
  | "Saudi Arabian"
  | "Citizen of Seychelles"
  | "Sierra Leone"
  | "Singaporean"
  | "Solomon Islander"
  | "Somali"
  | "Djiboutian"
  | "South African"
  | "Spanish"
  | "Sudanese"
  | "Surinamese"
  | "Swazi"
  | "Swedish"
  | "Swiss"
  | "Syrian"
  | "Tanzanian"
  | "Thai"
  | "Togolese"
  | "Trinidad and Tobago citizen"
  | "Citizen of the United Arab Emirates"
  | "Tunisian"
  | "Turkish"
  | "Ugandan"
  | "Egyptian"
  | "Burkinan"
  | "Uruguayan"
  | "United States citizen"
  | "Venezuelan"
  | "Vietnamese"
  | "Serbian"
  | "Zambian"
  | "Not Known"
  | "Stateless"
  | "Tongan"
  | "Senegalese"
  | "East Timorese"
  | "Bangladeshi"
  | "Cape Verdean"
  | "Equatorial Guinean"
  | "Maldivian"
  | "Namibian"
  | "Citizen of Guinea-Bissau"
  | "Citizen of Sao Tome and Principe"
  | "Comoran"
  | "Nauruan"
  | "Monegasque"
  | "Citizen of San Marino"
  | "Lichtensteiner"
  | "Estonian"
  | "Latvian"
  | "Lithuanian"
  | "Croatian"
  | "Slovenian"
  | "Armenian"
  | "Azerbaijani"
  | "Belarusian"
  | "Kazakh"
  | "Kyrgyz"
  | "Moldovan"
  | "Russian"
  | "Tajik"
  | "Turkmen"
  | "Ukrainian"
  | "Uzbek"
  | "Georgian"
  | "Citizen of Bosnia and Herzegovina"
  | "Czech"
  | "Slovak"
  | "Eritrean"
  | "Macedonian"
  | "Marshall Islander"
  | "Micronesian"
  | "Montenegrin"
  | "Palestinian"
  | "Vatican citizen"
  | "Sahrawi"
  | "Citizen of Kosovo"
  | "South Sudanese"
  | "Citizen of Cyprus (EU)"
  | "Citizen of Cyprus (non-EU)"
  | "Bermuda"
  | "Cayman Islands"
  | "Cypriot (European Union)"
  | "Cypriot (Non-European Union)"
  | "Cypriot (Not otherwise specified)"
  | "Faroese"
  | "Gibraltar"
  | "Isle of Man"
  | "Turks and Caicos Islands"
  | "Virgin Islander (British)";

interface NationalityEnum extends Enum<Nationality> {
  BritishCitizen: Nationality;
  BritishNationalOverseas: Nationality;
  BritishOverseasTeritoriesCitizen: Nationality;
  BritishOverseasCitizen: Nationality;
  BritishProtectedPerson: Nationality;
  BritishSubject: Nationality;
  Yemeni: Nationality;
  Afghan: Nationality;
  Albanian: Nationality;
  Algerian: Nationality;
  Andorran: Nationality;
  Angolan: Nationality;
  CitizenofAntiguaandBarbuda: Nationality;
  Argentine: Nationality;
  Australian: Nationality;
  Austrian: Nationality;
  Bahamian: Nationality;
  Bahraini: Nationality;
  Barbadian: Nationality;
  Belgian: Nationality;
  Bhutanese: Nationality;
  Bolivian: Nationality;
  CitizenofBotswana: Nationality;
  Brazilian: Nationality;
  CitizenofBrunei: Nationality;
  Bulgarian: Nationality;
  CitizenofMyanmar: Nationality;
  CitizenofBurundi: Nationality;
  Cambodian: Nationality;
  Cameroonian: Nationality;
  Canadian: Nationality;
  CitizenoftheCentralAfricanRepublic: Nationality;
  CitizenofSriLanka: Nationality;
  Chadian: Nationality;
  Chilean: Nationality;
  Chinese: Nationality;
  Colombian: Nationality;
  CitizenofTheDemocraticRepublicoftheCongo: Nationality;
  CitizenoftheRepublicoftheCongo: Nationality;
  CostaRican: Nationality;
  Cuban: Nationality;
  Beninese: Nationality;
  Danish: Nationality;
  Dominican: Nationality;
  CitizenoftheDominicanRepublic: Nationality;
  Ecuadorian: Nationality;
  Salvadorean: Nationality;
  Tuvaluan: Nationality;
  Ethiopian: Nationality;
  CitizenofFiji: Nationality;
  Finnish: Nationality;
  Taiwanese: Nationality;
  French: Nationality;
  Gabonese: Nationality;
  Gambian: Nationality;
  German: Nationality;
  Ghanaian: Nationality;
  CitizenofKiribati: Nationality;
  Greek: Nationality;
  Grenadian: Nationality;
  Guatemalan: Nationality;
  Guinean: Nationality;
  Guyanese: Nationality;
  Haitian: Nationality;
  Honduran: Nationality;
  CitizenofBelize: Nationality;
  CitizenofHongKongSpecialAdministrativeRegionofChina: Nationality;
  Hungarian: Nationality;
  Icelander: Nationality;
  Indian: Nationality;
  Indonesian: Nationality;
  Iranian: Nationality;
  Iraqi: Nationality;
  IrishCitizen: Nationality;
  Israeli: Nationality;
  Italian: Nationality;
  CitizenoftheIvoryCoast: Nationality;
  Jamaican: Nationality;
  Japanese: Nationality;
  Jordanian: Nationality;
  Kenyan: Nationality;
  SouthKorean: Nationality;
  CitizenoftheDemocraticPeopleRepublicofKorea: Nationality;
  Kuwaiti: Nationality;
  Lao: Nationality;
  Lebanese: Nationality;
  CitizenofLesotho: Nationality;
  Liberian: Nationality;
  Libyan: Nationality;
  Luxembourger: Nationality;
  CitizenofMacaoSpecialAdministrativeRegionofChina: Nationality;
  CitizenofMadagascar: Nationality;
  Malawian: Nationality;
  CitizenofMalaysia: Nationality;
  Malian: Nationality;
  Maltese: Nationality;
  Mauritanian: Nationality;
  Mauritian: Nationality;
  Mexican: Nationality;
  Mongolian: Nationality;
  Moroccan: Nationality;
  Mozambican: Nationality;
  Omani: Nationality;
  Nepalese: Nationality;
  Dutch: Nationality;
  CitizenofVanuatu: Nationality;
  NewZealander: Nationality;
  Nicaraguan: Nationality;
  CitizenofNiger: Nationality;
  Nigerian: Nationality;
  Norwegian: Nationality;
  Pakistani: Nationality;
  Panamanian: Nationality;
  PapuaNewGuinean: Nationality;
  Paraguayan: Nationality;
  Peruvian: Nationality;
  Filipino: Nationality;
  Polish: Nationality;
  Portuguese: Nationality;
  Qatari: Nationality;
  Zimbabwean: Nationality;
  Romanian: Nationality;
  Rwandan: Nationality;
  CitizenofStChristopherStKittsandNevis: Nationality;
  StLucia: Nationality;
  Vincentian: Nationality;
  Samoan: Nationality;
  SaudiArabian: Nationality;
  CitizenofSeychelles: Nationality;
  SierraLeone: Nationality;
  Singaporean: Nationality;
  SolomonIslander: Nationality;
  Somali: Nationality;
  Djiboutian: Nationality;
  SouthAfrican: Nationality;
  Spanish: Nationality;
  Sudanese: Nationality;
  Surinamese: Nationality;
  Swazi: Nationality;
  Swedish: Nationality;
  Swiss: Nationality;
  Syrian: Nationality;
  Tanzanian: Nationality;
  Thai: Nationality;
  Togolese: Nationality;
  TrinidadandTobagocitizen: Nationality;
  CitizenoftheUnitedArabEmirates: Nationality;
  Tunisian: Nationality;
  Turkish: Nationality;
  Ugandan: Nationality;
  Egyptian: Nationality;
  Burkinan: Nationality;
  Uruguayan: Nationality;
  UnitedStatescitizen: Nationality;
  Venezuelan: Nationality;
  Vietnamese: Nationality;
  Serbian: Nationality;
  Zambian: Nationality;
  NotKnown: Nationality;
  Stateless: Nationality;
  Tongan: Nationality;
  Senegalese: Nationality;
  EastTimorese: Nationality;
  Bangladeshi: Nationality;
  CapeVerdean: Nationality;
  EquatorialGuinean: Nationality;
  Maldivian: Nationality;
  Namibian: Nationality;
  CitizenofGuineaBissau: Nationality;
  CitizenofSaoTomeandPrincipe: Nationality;
  Comoran: Nationality;
  Nauruan: Nationality;
  Monegasque: Nationality;
  CitizenofSanMarino: Nationality;
  Lichtensteiner: Nationality;
  Estonian: Nationality;
  Latvian: Nationality;
  Lithuanian: Nationality;
  Croatian: Nationality;
  Slovenian: Nationality;
  Armenian: Nationality;
  Azerbaijani: Nationality;
  Belarusian: Nationality;
  Kazakh: Nationality;
  Kyrgyz: Nationality;
  Moldovan: Nationality;
  Russian: Nationality;
  Tajik: Nationality;
  Turkmen: Nationality;
  Ukrainian: Nationality;
  Uzbek: Nationality;
  Georgian: Nationality;
  CitizenofBosniaandHerzegovina: Nationality;
  Czech: Nationality;
  Slovak: Nationality;
  Eritrean: Nationality;
  Macedonian: Nationality;
  MarshallIslander: Nationality;
  Micronesian: Nationality;
  Montenegrin: Nationality;
  Palestinian: Nationality;
  Vaticancitizen: Nationality;
  Sahrawi: Nationality;
  CitizenofKosovo: Nationality;
  SouthSudanese: Nationality;
  CitizenofCyprusEU: Nationality;
  CitizenofCyprusnonEU: Nationality;
  Bermuda: Nationality;
  CaymanIslands: Nationality;
  CypriotEU: Nationality;
  CypriotNonEU: Nationality;
  CypriotNotSpecified: Nationality;
  Faroese: Nationality;
  Gibraltar: Nationality;
  IsleOfMan: Nationality;
  TurksAndCaicosIslands: Nationality;
  VirginIslanderBritish: Nationality;
}

export const NationalityEnum: NationalityEnum = {
  name: "Nationality",

  BritishCitizen: "British Citizen",
  BritishNationalOverseas: "British National (Overseas)",
  BritishOverseasTeritoriesCitizen: "British Overseas Teritories Citizen",
  BritishOverseasCitizen: "British Overseas Citizen",
  BritishProtectedPerson: "British Protected Person",
  BritishSubject: "British Subject",
  Yemeni: "Yemeni",
  Afghan: "Afghan",
  Albanian: "Albanian",
  Algerian: "Algerian",
  Andorran: "Andorran",
  Angolan: "Angolan",
  CitizenofAntiguaandBarbuda: "Citizen of Antigua and Barbuda",
  Argentine: "Argentine",
  Australian: "Australian",
  Austrian: "Austrian",
  Bahamian: "Bahamian",
  Bahraini: "Bahraini",
  Barbadian: "Barbadian",
  Belgian: "Belgian",
  Bhutanese: "Bhutanese",
  Bolivian: "Bolivian",
  CitizenofBotswana: "Citizen of Botswana",
  Brazilian: "Brazilian",
  CitizenofBrunei: "Citizen of Brunei",
  Bulgarian: "Bulgarian",
  CitizenofMyanmar: "Citizen of Myanmar",
  CitizenofBurundi: "Citizen of Burundi",
  Cambodian: "Cambodian",
  Cameroonian: "Cameroonian",
  Canadian: "Canadian",
  CitizenoftheCentralAfricanRepublic: "Citizen of the Central African Republic",
  CitizenofSriLanka: "Citizen of Sri Lanka",
  Chadian: "Chadian",
  Chilean: "Chilean",
  Chinese: "Chinese",
  Colombian: "Colombian",
  CitizenofTheDemocraticRepublicoftheCongo: "Citizen of The Democratic Republic of the Congo",
  CitizenoftheRepublicoftheCongo: "Citizen of the Republic of the Congo",
  CostaRican: "Costa Rican",
  Cuban: "Cuban",
  Beninese: "Beninese",
  Danish: "Danish",
  Dominican: "Dominican",
  CitizenoftheDominicanRepublic: "Citizen of the Dominican Republic",
  Ecuadorian: "Ecuadorian",
  Salvadorean: "Salvadorean",
  Tuvaluan: "Tuvaluan",
  Ethiopian: "Ethiopian",
  CitizenofFiji: "Citizen of Fiji",
  Finnish: "Finnish",
  Taiwanese: "Taiwanese",
  French: "French",
  Gabonese: "Gabonese",
  Gambian: "Gambian",
  German: "German",
  Ghanaian: "Ghanaian",
  CitizenofKiribati: "Citizen of Kiribati",
  Greek: "Greek",
  Grenadian: "Grenadian",
  Guatemalan: "Guatemalan",
  Guinean: "Guinean",
  Guyanese: "Guyanese",
  Haitian: "Haitian",
  Honduran: "Honduran",
  CitizenofBelize: "Citizen of Belize",
  CitizenofHongKongSpecialAdministrativeRegionofChina: "Citizen of Hong Kong (Special Administrative Region of China)",
  Hungarian: "Hungarian",
  Icelander: "Icelander",
  Indian: "Indian",
  Indonesian: "Indonesian",
  Iranian: "Iranian",
  Iraqi: "Iraqi",
  IrishCitizen: "Irish Citizen",
  Israeli: "Israeli",
  Italian: "Italian",
  CitizenoftheIvoryCoast: "Citizen of the Ivory Coast",
  Jamaican: "Jamaican",
  Japanese: "Japanese",
  Jordanian: "Jordanian",
  Kenyan: "Kenyan",
  SouthKorean: "South Korean",
  CitizenoftheDemocraticPeopleRepublicofKorea: "Citizen of the Democratic People's Republic of Korea",
  Kuwaiti: "Kuwaiti",
  Lao: "Lao",
  Lebanese: "Lebanese",
  CitizenofLesotho: "Citizen of Lesotho",
  Liberian: "Liberian",
  Libyan: "Libyan",
  Luxembourger: "Luxembourger",
  CitizenofMacaoSpecialAdministrativeRegionofChina: "Citizen of Macao (Special Administrative Region of China)",
  CitizenofMadagascar: "Citizen of Madagascar",
  Malawian: "Malawian",
  CitizenofMalaysia: "Citizen of Malaysia",
  Malian: "Malian",
  Maltese: "Maltese",
  Mauritanian: "Mauritanian",
  Mauritian: "Mauritian",
  Mexican: "Mexican",
  Mongolian: "Mongolian",
  Moroccan: "Moroccan",
  Mozambican: "Mozambican",
  Omani: "Omani",
  Nepalese: "Nepalese",
  Dutch: "Dutch",
  CitizenofVanuatu: "Citizen of Vanuatu",
  NewZealander: "New Zealander",
  Nicaraguan: "Nicaraguan",
  CitizenofNiger: "Citizen of Niger",
  Nigerian: "Nigerian",
  Norwegian: "Norwegian",
  Pakistani: "Pakistani",
  Panamanian: "Panamanian",
  PapuaNewGuinean: "Papua New Guinean",
  Paraguayan: "Paraguayan",
  Peruvian: "Peruvian",
  Filipino: "Filipino",
  Polish: "Polish",
  Portuguese: "Portuguese",
  Qatari: "Qatari",
  Zimbabwean: "Zimbabwean",
  Romanian: "Romanian",
  Rwandan: "Rwandan",
  CitizenofStChristopherStKittsandNevis: "Citizen of St Christopher (St Kitts) and Nevis",
  StLucia: "St Lucia",
  Vincentian: "Vincentian",
  Samoan: "Samoan",
  SaudiArabian: "Saudi Arabian",
  CitizenofSeychelles: "Citizen of Seychelles",
  SierraLeone: "Sierra Leone",
  Singaporean: "Singaporean",
  SolomonIslander: "Solomon Islander",
  Somali: "Somali",
  Djiboutian: "Djiboutian",
  SouthAfrican: "South African",
  Spanish: "Spanish",
  Sudanese: "Sudanese",
  Surinamese: "Surinamese",
  Swazi: "Swazi",
  Swedish: "Swedish",
  Swiss: "Swiss",
  Syrian: "Syrian",
  Tanzanian: "Tanzanian",
  Thai: "Thai",
  Togolese: "Togolese",
  TrinidadandTobagocitizen: "Trinidad and Tobago citizen",
  CitizenoftheUnitedArabEmirates: "Citizen of the United Arab Emirates",
  Tunisian: "Tunisian",
  Turkish: "Turkish",
  Ugandan: "Ugandan",
  Egyptian: "Egyptian",
  Burkinan: "Burkinan",
  Uruguayan: "Uruguayan",
  UnitedStatescitizen: "United States citizen",
  Venezuelan: "Venezuelan",
  Vietnamese: "Vietnamese",
  Serbian: "Serbian",
  Zambian: "Zambian",
  NotKnown: "Not Known",
  Stateless: "Stateless",
  Tongan: "Tongan",
  Senegalese: "Senegalese",
  EastTimorese: "East Timorese",
  Bangladeshi: "Bangladeshi",
  CapeVerdean: "Cape Verdean",
  EquatorialGuinean: "Equatorial Guinean",
  Maldivian: "Maldivian",
  Namibian: "Namibian",
  CitizenofGuineaBissau: "Citizen of Guinea-Bissau",
  CitizenofSaoTomeandPrincipe: "Citizen of Sao Tome and Principe",
  Comoran: "Comoran",
  Nauruan: "Nauruan",
  Monegasque: "Monegasque",
  CitizenofSanMarino: "Citizen of San Marino",
  Lichtensteiner: "Lichtensteiner",
  Estonian: "Estonian",
  Latvian: "Latvian",
  Lithuanian: "Lithuanian",
  Croatian: "Croatian",
  Slovenian: "Slovenian",
  Armenian: "Armenian",
  Azerbaijani: "Azerbaijani",
  Belarusian: "Belarusian",
  Kazakh: "Kazakh",
  Kyrgyz: "Kyrgyz",
  Moldovan: "Moldovan",
  Russian: "Russian",
  Tajik: "Tajik",
  Turkmen: "Turkmen",
  Ukrainian: "Ukrainian",
  Uzbek: "Uzbek",
  Georgian: "Georgian",
  CitizenofBosniaandHerzegovina: "Citizen of Bosnia and Herzegovina",
  Czech: "Czech",
  Slovak: "Slovak",
  Eritrean: "Eritrean",
  Macedonian: "Macedonian",
  MarshallIslander: "Marshall Islander",
  Micronesian: "Micronesian",
  Montenegrin: "Montenegrin",
  Palestinian: "Palestinian",
  Vaticancitizen: "Vatican citizen",
  Sahrawi: "Sahrawi",
  CitizenofKosovo: "Citizen of Kosovo",
  SouthSudanese: "South Sudanese",
  CitizenofCyprusEU: "Citizen of Cyprus (EU)",
  CitizenofCyprusnonEU: "Citizen of Cyprus (non-EU)",
  Bermuda: "Bermuda",
  CaymanIslands: "Cayman Islands",
  CypriotEU: "Cypriot (European Union)",
  CypriotNonEU: "Cypriot (Non-European Union)",
  CypriotNotSpecified: "Cypriot (Not otherwise specified)",
  Faroese: "Faroese",
  Gibraltar: "Gibraltar",
  IsleOfMan: "Isle of Man",
  TurksAndCaicosIslands: "Turks and Caicos Islands",
  VirginIslanderBritish: "Virgin Islander (British)",

  entries: [
    { value: "British Citizen", label: "British Citizen" },
    { value: "British National (Overseas)", label: "British National (Overseas)" },
    { value: "British Overseas Teritories Citizen", label: "British Overseas Teritories Citizen" },
    { value: "British Overseas Citizen", label: "British Overseas Citizen" },
    { value: "British Protected Person", label: "British Protected Person" },
    { value: "British Subject", label: "British Subject" },
    { value: "Yemeni", label: "Yemeni" },
    { value: "Afghan", label: "Afghan" },
    { value: "Albanian", label: "Albanian" },
    { value: "Algerian", label: "Algerian" },
    { value: "Andorran", label: "Andorran" },
    { value: "Angolan", label: "Angolan" },
    { value: "Citizen of Antigua and Barbuda", label: "Citizen of Antigua and Barbuda" },
    { value: "Argentine", label: "Argentine" },
    { value: "Australian", label: "Australian" },
    { value: "Austrian", label: "Austrian" },
    { value: "Bahamian", label: "Bahamian" },
    { value: "Bahraini", label: "Bahraini" },
    { value: "Barbadian", label: "Barbadian" },
    { value: "Belgian", label: "Belgian" },
    { value: "Bhutanese", label: "Bhutanese" },
    { value: "Bolivian", label: "Bolivian" },
    { value: "Citizen of Botswana", label: "Citizen of Botswana" },
    { value: "Brazilian", label: "Brazilian" },
    { value: "Citizen of Brunei", label: "Citizen of Brunei" },
    { value: "Bulgarian", label: "Bulgarian" },
    { value: "Citizen of Myanmar", label: "Citizen of Myanmar" },
    { value: "Citizen of Burundi", label: "Citizen of Burundi" },
    { value: "Cambodian", label: "Cambodian" },
    { value: "Cameroonian", label: "Cameroonian" },
    { value: "Canadian", label: "Canadian" },
    { value: "Citizen of the Central African Republic", label: "Citizen of the Central African Republic" },
    { value: "Citizen of Sri Lanka", label: "Citizen of Sri Lanka" },
    { value: "Chadian", label: "Chadian" },
    { value: "Chilean", label: "Chilean" },
    { value: "Chinese", label: "Chinese" },
    { value: "Colombian", label: "Colombian" },
    { value: "Citizen of The Democratic Republic of the Congo", label: "Citizen of The Democratic Republic of the Congo" },
    { value: "Citizen of the Republic of the Congo", label: "Citizen of the Republic of the Congo" },
    { value: "Costa Rican", label: "Costa Rican" },
    { value: "Cuban", label: "Cuban" },
    { value: "Beninese", label: "Beninese" },
    { value: "Danish", label: "Danish" },
    { value: "Dominican", label: "Dominican" },
    { value: "Citizen of the Dominican Republic", label: "Citizen of the Dominican Republic" },
    { value: "Ecuadorian", label: "Ecuadorian" },
    { value: "Salvadorean", label: "Salvadorean" },
    { value: "Tuvaluan", label: "Tuvaluan" },
    { value: "Ethiopian", label: "Ethiopian" },
    { value: "Citizen of Fiji", label: "Citizen of Fiji" },
    { value: "Finnish", label: "Finnish" },
    { value: "Taiwanese", label: "Taiwanese" },
    { value: "French", label: "French" },
    { value: "Gabonese", label: "Gabonese" },
    { value: "Gambian", label: "Gambian" },
    { value: "German", label: "German" },
    { value: "Ghanaian", label: "Ghanaian" },
    { value: "Citizen of Kiribati", label: "Citizen of Kiribati" },
    { value: "Greek", label: "Greek" },
    { value: "Grenadian", label: "Grenadian" },
    { value: "Guatemalan", label: "Guatemalan" },
    { value: "Guinean", label: "Guinean" },
    { value: "Guyanese", label: "Guyanese" },
    { value: "Haitian", label: "Haitian" },
    { value: "Honduran", label: "Honduran" },
    { value: "Citizen of Belize", label: "Citizen of Belize" },
    {
      value: "Citizen of Hong Kong (Special Administrative Region of China)",
      label: "Citizen of Hong Kong (Special Administrative Region of China)",
    },
    { value: "Hungarian", label: "Hungarian" },
    { value: "Icelander", label: "Icelander" },
    { value: "Indian", label: "Indian" },
    { value: "Indonesian", label: "Indonesian" },
    { value: "Iranian", label: "Iranian" },
    { value: "Iraqi", label: "Iraqi" },
    { value: "Irish Citizen", label: "Irish Citizen" },
    { value: "Israeli", label: "Israeli" },
    { value: "Italian", label: "Italian" },
    { value: "Citizen of the Ivory Coast", label: "Citizen of the Ivory Coast" },
    { value: "Jamaican", label: "Jamaican" },
    { value: "Japanese", label: "Japanese" },
    { value: "Jordanian", label: "Jordanian" },
    { value: "Kenyan", label: "Kenyan" },
    { value: "South Korean", label: "South Korean" },
    { value: "Citizen of the Democratic People's Republic of Korea", label: "Citizen of the Democratic People's Republic of Korea" },
    { value: "Kuwaiti", label: "Kuwaiti" },
    { value: "Lao", label: "Lao" },
    { value: "Lebanese", label: "Lebanese" },
    { value: "Citizen of Lesotho", label: "Citizen of Lesotho" },
    { value: "Liberian", label: "Liberian" },
    { value: "Libyan", label: "Libyan" },
    { value: "Luxembourger", label: "Luxembourger" },
    { value: "Citizen of Macao (Special Administrative Region of China)", label: "Citizen of Macao (Special Administrative Region of China)" },
    { value: "Citizen of Madagascar", label: "Citizen of Madagascar" },
    { value: "Malawian", label: "Malawian" },
    { value: "Citizen of Malaysia", label: "Citizen of Malaysia" },
    { value: "Malian", label: "Malian" },
    { value: "Maltese", label: "Maltese" },
    { value: "Mauritanian", label: "Mauritanian" },
    { value: "Mauritian", label: "Mauritian" },
    { value: "Mexican", label: "Mexican" },
    { value: "Mongolian", label: "Mongolian" },
    { value: "Moroccan", label: "Moroccan" },
    { value: "Mozambican", label: "Mozambican" },
    { value: "Omani", label: "Omani" },
    { value: "Nepalese", label: "Nepalese" },
    { value: "Dutch", label: "Dutch" },
    { value: "Citizen of Vanuatu", label: "Citizen of Vanuatu" },
    { value: "New Zealander", label: "New Zealander" },
    { value: "Nicaraguan", label: "Nicaraguan" },
    { value: "Citizen of Niger", label: "Citizen of Niger" },
    { value: "Nigerian", label: "Nigerian" },
    { value: "Norwegian", label: "Norwegian" },
    { value: "Pakistani", label: "Pakistani" },
    { value: "Panamanian", label: "Panamanian" },
    { value: "Papua New Guinean", label: "Papua New Guinean" },
    { value: "Paraguayan", label: "Paraguayan" },
    { value: "Peruvian", label: "Peruvian" },
    { value: "Filipino", label: "Filipino" },
    { value: "Polish", label: "Polish" },
    { value: "Portuguese", label: "Portuguese" },
    { value: "Qatari", label: "Qatari" },
    { value: "Zimbabwean", label: "Zimbabwean" },
    { value: "Romanian", label: "Romanian" },
    { value: "Rwandan", label: "Rwandan" },
    { value: "Citizen of St Christopher (St Kitts) and Nevis", label: "Citizen of St Christopher (St Kitts) and Nevis" },
    { value: "St Lucia", label: "St Lucia" },
    { value: "Vincentian", label: "Vincentian" },
    { value: "Samoan", label: "Samoan" },
    { value: "Saudi Arabian", label: "Saudi Arabian" },
    { value: "Citizen of Seychelles", label: "Citizen of Seychelles" },
    { value: "Sierra Leone", label: "Sierra Leone" },
    { value: "Singaporean", label: "Singaporean" },
    { value: "Solomon Islander", label: "Solomon Islander" },
    { value: "Somali", label: "Somali" },
    { value: "Djiboutian", label: "Djiboutian" },
    { value: "South African", label: "South African" },
    { value: "Spanish", label: "Spanish" },
    { value: "Sudanese", label: "Sudanese" },
    { value: "Surinamese", label: "Surinamese" },
    { value: "Swazi", label: "Swazi" },
    { value: "Swedish", label: "Swedish" },
    { value: "Swiss", label: "Swiss" },
    { value: "Syrian", label: "Syrian" },
    { value: "Tanzanian", label: "Tanzanian" },
    { value: "Thai", label: "Thai" },
    { value: "Togolese", label: "Togolese" },
    { value: "Trinidad and Tobago citizen", label: "Trinidad and Tobago citizen" },
    { value: "Citizen of the United Arab Emirates", label: "Citizen of the United Arab Emirates" },
    { value: "Tunisian", label: "Tunisian" },
    { value: "Turkish", label: "Turkish" },
    { value: "Ugandan", label: "Ugandan" },
    { value: "Egyptian", label: "Egyptian" },
    { value: "Burkinan", label: "Burkinan" },
    { value: "Uruguayan", label: "Uruguayan" },
    { value: "United States citizen", label: "United States citizen" },
    { value: "Venezuelan", label: "Venezuelan" },
    { value: "Vietnamese", label: "Vietnamese" },
    { value: "Serbian", label: "Serbian" },
    { value: "Zambian", label: "Zambian" },
    { value: "Not Known", label: "Not Known" },
    { value: "Stateless", label: "Stateless" },
    { value: "Tongan", label: "Tongan" },
    { value: "Senegalese", label: "Senegalese" },
    { value: "East Timorese", label: "East Timorese" },
    { value: "Bangladeshi", label: "Bangladeshi" },
    { value: "Cape Verdean", label: "Cape Verdean" },
    { value: "Equatorial Guinean", label: "Equatorial Guinean" },
    { value: "Maldivian", label: "Maldivian" },
    { value: "Namibian", label: "Namibian" },
    { value: "Citizen of Guinea-Bissau", label: "Citizen of Guinea-Bissau" },
    { value: "Citizen of Sao Tome and Principe", label: "Citizen of Sao Tome and Principe" },
    { value: "Comoran", label: "Comoran" },
    { value: "Nauruan", label: "Nauruan" },
    { value: "Monegasque", label: "Monegasque" },
    { value: "Citizen of San Marino", label: "Citizen of San Marino" },
    { value: "Lichtensteiner", label: "Lichtensteiner" },
    { value: "Estonian", label: "Estonian" },
    { value: "Latvian", label: "Latvian" },
    { value: "Lithuanian", label: "Lithuanian" },
    { value: "Croatian", label: "Croatian" },
    { value: "Slovenian", label: "Slovenian" },
    { value: "Armenian", label: "Armenian" },
    { value: "Azerbaijani", label: "Azerbaijani" },
    { value: "Belarusian", label: "Belarusian" },
    { value: "Kazakh", label: "Kazakh" },
    { value: "Kyrgyz", label: "Kyrgyz" },
    { value: "Moldovan", label: "Moldovan" },
    { value: "Russian", label: "Russian" },
    { value: "Tajik", label: "Tajik" },
    { value: "Turkmen", label: "Turkmen" },
    { value: "Ukrainian", label: "Ukrainian" },
    { value: "Uzbek", label: "Uzbek" },
    { value: "Georgian", label: "Georgian" },
    { value: "Citizen of Bosnia and Herzegovina", label: "Citizen of Bosnia and Herzegovina" },
    { value: "Czech", label: "Czech" },
    { value: "Slovak", label: "Slovak" },
    { value: "Eritrean", label: "Eritrean" },
    { value: "Macedonian", label: "Macedonian" },
    { value: "Marshall Islander", label: "Marshall Islander" },
    { value: "Micronesian", label: "Micronesian" },
    { value: "Montenegrin", label: "Montenegrin" },
    { value: "Palestinian", label: "Palestinian" },
    { value: "Vatican citizen", label: "Vatican citizen" },
    { value: "Sahrawi", label: "Sahrawi" },
    { value: "Citizen of Kosovo", label: "Citizen of Kosovo" },
    { value: "South Sudanese", label: "South Sudanese" },
    { value: "Citizen of Cyprus (EU)", label: "Citizen of Cyprus (EU)" },
    { value: "Citizen of Cyprus (non-EU)", label: "Citizen of Cyprus (non-EU)" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Cayman Islands", label: "Cayman Islands" },
    { value: "Cypriot (European Union)", label: "Cypriot (European Union)" },
    { value: "Cypriot (Non-European Union)", label: "Cypriot (Non-European Union)" },
    { value: "Cypriot (Not otherwise specified)", label: "Cypriot (Not otherwise specified)" },
    { value: "Faroese", label: "Faroese" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Isle of Man", label: "Isle of Man" },
    { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { value: "Virgin Islander (British)", label: "Virgin Islander (British)" },
  ],

  values: [
    "British Citizen",
    "British National (Overseas)",
    "British Overseas Teritories Citizen",
    "British Overseas Citizen",
    "British Protected Person",
    "British Subject",
    "Yemeni",
    "Afghan",
    "Albanian",
    "Algerian",
    "Andorran",
    "Angolan",
    "Citizen of Antigua and Barbuda",
    "Argentine",
    "Australian",
    "Austrian",
    "Bahamian",
    "Bahraini",
    "Barbadian",
    "Belgian",
    "Bhutanese",
    "Bolivian",
    "Citizen of Botswana",
    "Brazilian",
    "Citizen of Brunei",
    "Bulgarian",
    "Citizen of Myanmar",
    "Citizen of Burundi",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Citizen of the Central African Republic",
    "Citizen of Sri Lanka",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Citizen of The Democratic Republic of the Congo",
    "Citizen of the Republic of the Congo",
    "Costa Rican",
    "Cuban",
    "Beninese",
    "Danish",
    "Dominican",
    "Citizen of the Dominican Republic",
    "Ecuadorian",
    "Salvadorean",
    "Tuvaluan",
    "Ethiopian",
    "Citizen of Fiji",
    "Finnish",
    "Taiwanese",
    "French",
    "Gabonese",
    "Gambian",
    "German",
    "Ghanaian",
    "Citizen of Kiribati",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Honduran",
    "Citizen of Belize",
    "Citizen of Hong Kong (Special Administrative Region of China)",
    "Hungarian",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish Citizen",
    "Israeli",
    "Italian",
    "Citizen of the Ivory Coast",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kenyan",
    "South Korean",
    "Citizen of the Democratic People's Republic of Korea",
    "Kuwaiti",
    "Lao",
    "Lebanese",
    "Citizen of Lesotho",
    "Liberian",
    "Libyan",
    "Luxembourger",
    "Citizen of Macao (Special Administrative Region of China)",
    "Citizen of Madagascar",
    "Malawian",
    "Citizen of Malaysia",
    "Malian",
    "Maltese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Mongolian",
    "Moroccan",
    "Mozambican",
    "Omani",
    "Nepalese",
    "Dutch",
    "Citizen of Vanuatu",
    "New Zealander",
    "Nicaraguan",
    "Citizen of Niger",
    "Nigerian",
    "Norwegian",
    "Pakistani",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Filipino",
    "Polish",
    "Portuguese",
    "Qatari",
    "Zimbabwean",
    "Romanian",
    "Rwandan",
    "Citizen of St Christopher (St Kitts) and Nevis",
    "St Lucia",
    "Vincentian",
    "Samoan",
    "Saudi Arabian",
    "Citizen of Seychelles",
    "Sierra Leone",
    "Singaporean",
    "Solomon Islander",
    "Somali",
    "Djiboutian",
    "South African",
    "Spanish",
    "Sudanese",
    "Surinamese",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Trinidad and Tobago citizen",
    "Citizen of the United Arab Emirates",
    "Tunisian",
    "Turkish",
    "Ugandan",
    "Egyptian",
    "Burkinan",
    "Uruguayan",
    "United States citizen",
    "Venezuelan",
    "Vietnamese",
    "Serbian",
    "Zambian",
    "Not Known",
    "Stateless",
    "Tongan",
    "Senegalese",
    "East Timorese",
    "Bangladeshi",
    "Cape Verdean",
    "Equatorial Guinean",
    "Maldivian",
    "Namibian",
    "Citizen of Guinea-Bissau",
    "Citizen of Sao Tome and Principe",
    "Comoran",
    "Nauruan",
    "Monegasque",
    "Citizen of San Marino",
    "Lichtensteiner",
    "Estonian",
    "Latvian",
    "Lithuanian",
    "Croatian",
    "Slovenian",
    "Armenian",
    "Azerbaijani",
    "Belarusian",
    "Kazakh",
    "Kyrgyz",
    "Moldovan",
    "Russian",
    "Tajik",
    "Turkmen",
    "Ukrainian",
    "Uzbek",
    "Georgian",
    "Citizen of Bosnia and Herzegovina",
    "Czech",
    "Slovak",
    "Eritrean",
    "Macedonian",
    "Marshall Islander",
    "Micronesian",
    "Montenegrin",
    "Palestinian",
    "Vatican citizen",
    "Sahrawi",
    "Citizen of Kosovo",
    "South Sudanese",
    "Citizen of Cyprus (EU)",
    "Citizen of Cyprus (non-EU)",
    "Bermuda",
    "Cayman Islands",
    "Cypriot (European Union)",
    "Cypriot (Non-European Union)",
    "Cypriot (Not otherwise specified)",
    "Faroese",
    "Gibraltar",
    "Isle of Man",
    "Turks and Caicos Islands",
    "Virgin Islander (British)",
  ],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<Nationality> {
    return NationalityEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<Nationality | null> {
    return [
      { value: null, label: blankLabel },
      ...NationalityEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is Nationality {
    return (NationalityEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return NationalityEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type UkResidency = "A" | "B" | "C";

interface UkResidencyEnum extends Enum<UkResidency> {
  A: UkResidency;
  B: UkResidency;
  C: UkResidency;
}

export const UkResidencyEnum: UkResidencyEnum = {
  name: "UkResidency",

  A: "A",
  B: "B",
  C: "C",

  entries: [
    { value: "A", label: "3 years or more" },
    { value: "B", label: "Less than 3 years" },
    { value: "C", label: "Yet to arrive" },
  ],

  values: ["A", "B", "C"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<UkResidency> {
    return UkResidencyEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<UkResidency | null> {
    return [
      { value: null, label: blankLabel },
      ...UkResidencyEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is UkResidency {
    return (UkResidencyEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return UkResidencyEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type UkImmigrationStatus = "A" | "B" | "C" | "D";

interface UkImmigrationStatusEnum extends Enum<UkImmigrationStatus> {
  A: UkImmigrationStatus;
  B: UkImmigrationStatus;
  C: UkImmigrationStatus;
  D: UkImmigrationStatus;
}

export const UkImmigrationStatusEnum: UkImmigrationStatusEnum = {
  name: "UkImmigrationStatus",

  A: "A",
  B: "B",
  C: "C",
  D: "D",

  entries: [
    { value: "A", label: "British/Irish national or Indefinite Leave to Remain/Enter" },
    { value: "B", label: "EU National with Pre-settled status" },
    { value: "C", label: "Refugee status" },
    { value: "D", label: "Other" },
  ],

  values: ["A", "B", "C", "D"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<UkImmigrationStatus> {
    return UkImmigrationStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<UkImmigrationStatus | null> {
    return [
      { value: null, label: blankLabel },
      ...UkImmigrationStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is UkImmigrationStatus {
    return (UkImmigrationStatusEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return UkImmigrationStatusEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type FeeStatus = "HHC" | "HHP" | "HCC" | "HCP" | "HEC" | "HEP" | "OEC" | "OEP" | "OHP" | "OHC" | "OOC" | "OOP" | "QQP";

interface FeeStatusEnum extends Enum<FeeStatus> {
  Home: FeeStatus;
  HomeProvisional: FeeStatus;
  Ci: FeeStatus;
  CiProvisional: FeeStatus;
  HomeEu: FeeStatus;
  HomeEuProvisional: FeeStatus;
  OverseasEu: FeeStatus;
  OverseasEuProvisional: FeeStatus;
  OverseasHomeProvisional: FeeStatus;
  OverseasHome: FeeStatus;
  Overseas: FeeStatus;
  OverseasProvisional: FeeStatus;
  Query: FeeStatus;
}

export const FeeStatusEnum: FeeStatusEnum = {
  name: "FeeStatus",

  Home: "HHC",
  HomeProvisional: "HHP",
  Ci: "HCC",
  CiProvisional: "HCP",
  HomeEu: "HEC",
  HomeEuProvisional: "HEP",
  OverseasEu: "OEC",
  OverseasEuProvisional: "OEP",
  OverseasHomeProvisional: "OHP",
  OverseasHome: "OHC",
  Overseas: "OOC",
  OverseasProvisional: "OOP",
  Query: "QQP",

  entries: [
    { value: "HHC", label: "Home" },
    { value: "HHP", label: "Home (provisional)" },
    { value: "HCC", label: "Channel Islands" },
    { value: "HCP", label: "Channel Islands (provisional)" },
    { value: "HEC", label: "Home EU" },
    { value: "HEP", label: "Home EU (provisional)" },
    { value: "OEC", label: "Overseas EU" },
    { value: "OEP", label: "Overseas EU (provisional)" },
    { value: "OHP", label: "Overseas (but charged Home fees) (provisional)" },
    { value: "OHC", label: "Overseas (but charged Home fees)" },
    { value: "OOC", label: "Overseas" },
    { value: "OOP", label: "Overseas (provisional)" },
    { value: "QQP", label: "Query" },
  ],

  values: ["HHC", "HHP", "HCC", "HCP", "HEC", "HEP", "OEC", "OEP", "OHP", "OHC", "OOC", "OOP", "QQP"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<FeeStatus> {
    return FeeStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<FeeStatus | null> {
    return [
      { value: null, label: blankLabel },
      ...FeeStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is FeeStatus {
    return (FeeStatusEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return FeeStatusEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type FeeCategory = "Home" | "Eu" | "Overseas";

interface FeeCategoryEnum extends Enum<FeeCategory> {
  Home: FeeCategory;
  Eu: FeeCategory;
  Overseas: FeeCategory;
}

export const FeeCategoryEnum: FeeCategoryEnum = {
  name: "FeeCategory",

  Home: "Home",
  Eu: "Eu",
  Overseas: "Overseas",

  entries: [
    { value: "Home", label: "Home" },
    { value: "Eu", label: "Eu" },
    { value: "Overseas", label: "Overseas" },
  ],

  values: ["Home", "Eu", "Overseas"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<FeeCategory> {
    return FeeCategoryEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<FeeCategory | null> {
    return [
      { value: null, label: blankLabel },
      ...FeeCategoryEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is FeeCategory {
    return (FeeCategoryEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return FeeCategoryEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type FeeStatusType = "FeeStatusConfirmed" | "FeeStatusProvisional" | "NoFeeStatus";

interface FeeStatusTypeEnum extends Enum<FeeStatusType> {
  FeeStatusConfirmed: FeeStatusType;
  FeeStatusProvisional: FeeStatusType;
  NoFeeStatus: FeeStatusType;
}

export const FeeStatusTypeEnum: FeeStatusTypeEnum = {
  name: "FeeStatusType",

  FeeStatusConfirmed: "FeeStatusConfirmed",
  FeeStatusProvisional: "FeeStatusProvisional",
  NoFeeStatus: "NoFeeStatus",

  entries: [
    { value: "FeeStatusConfirmed", label: "FeeStatusConfirmed" },
    { value: "FeeStatusProvisional", label: "FeeStatusProvisional" },
    { value: "NoFeeStatus", label: "NoFeeStatus" },
  ],

  values: ["FeeStatusConfirmed", "FeeStatusProvisional", "NoFeeStatus"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<FeeStatusType> {
    return FeeStatusTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<FeeStatusType | null> {
    return [
      { value: null, label: blankLabel },
      ...FeeStatusTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is FeeStatusType {
    return (FeeStatusTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return FeeStatusTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type EntryPoint = "01" | "11" | "21" | "31" | "41" | "51" | "61";

interface EntryPointEnum extends Enum<EntryPoint> {
  Foundation: EntryPoint;
  First: EntryPoint;
  Second: EntryPoint;
  Third: EntryPoint;
  Fourth: EntryPoint;
  Fifth: EntryPoint;
  Sixth: EntryPoint;
}

export const EntryPointEnum: EntryPointEnum = {
  name: "EntryPoint",

  Foundation: "01",
  First: "11",
  Second: "21",
  Third: "31",
  Fourth: "41",
  Fifth: "51",
  Sixth: "61",

  entries: [
    { value: "01", label: "Foundation year" },
    { value: "11", label: "First year" },
    { value: "21", label: "Second year" },
    { value: "31", label: "Third year" },
    { value: "41", label: "Fourth year" },
    { value: "51", label: "Fifth year" },
    { value: "61", label: "Sixth year" },
  ],

  values: ["01", "11", "21", "31", "41", "51", "61"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<EntryPoint> {
    return EntryPointEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<EntryPoint | null> {
    return [
      { value: null, label: blankLabel },
      ...EntryPointEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is EntryPoint {
    return (EntryPointEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return EntryPointEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type InitialDecision = "U" | "C" | "R";

interface InitialDecisionEnum extends Enum<InitialDecision> {
  Unconditional: InitialDecision;
  Conditional: InitialDecision;
  Reject: InitialDecision;
}

export const InitialDecisionEnum: InitialDecisionEnum = {
  name: "InitialDecision",

  Unconditional: "U",
  Conditional: "C",
  Reject: "R",

  entries: [
    { value: "U", label: "Unconditional" },
    { value: "C", label: "Conditional" },
    { value: "R", label: "Reject" },
  ],

  values: ["U", "C", "R"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<InitialDecision> {
    return InitialDecisionEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<InitialDecision | null> {
    return [
      { value: null, label: blankLabel },
      ...InitialDecisionEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is InitialDecision {
    return (InitialDecisionEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return InitialDecisionEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type InitialResponse = "F" | "I" | "D";

interface InitialResponseEnum extends Enum<InitialResponse> {
  Firm: InitialResponse;
  Insurance: InitialResponse;
  Declined: InitialResponse;
}

export const InitialResponseEnum: InitialResponseEnum = {
  name: "InitialResponse",

  Firm: "F",
  Insurance: "I",
  Declined: "D",

  entries: [
    { value: "F", label: "Firm" },
    { value: "I", label: "Insurance" },
    { value: "D", label: "Declined" },
  ],

  values: ["F", "I", "D"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<InitialResponse> {
    return InitialResponseEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<InitialResponse | null> {
    return [
      { value: null, label: blankLabel },
      ...InitialResponseEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is InitialResponse {
    return (InitialResponseEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return InitialResponseEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ConfirmationDecision = "U" | "R";

interface ConfirmationDecisionEnum extends Enum<ConfirmationDecision> {
  Unconditional: ConfirmationDecision;
  Rejected: ConfirmationDecision;
}

export const ConfirmationDecisionEnum: ConfirmationDecisionEnum = {
  name: "ConfirmationDecision",

  Unconditional: "U",
  Rejected: "R",

  entries: [
    { value: "U", label: "Unconditional" },
    { value: "R", label: "Rejected" },
  ],

  values: ["U", "R"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ConfirmationDecision> {
    return ConfirmationDecisionEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ConfirmationDecision | null> {
    return [
      { value: null, label: blankLabel },
      ...ConfirmationDecisionEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ConfirmationDecision {
    return (ConfirmationDecisionEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ConfirmationDecisionEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ConfirmationResponse = "F" | "I" | "D";

interface ConfirmationResponseEnum extends Enum<ConfirmationResponse> {
  Firm: ConfirmationResponse;
  Insurance: ConfirmationResponse;
  Declined: ConfirmationResponse;
}

export const ConfirmationResponseEnum: ConfirmationResponseEnum = {
  name: "ConfirmationResponse",

  Firm: "F",
  Insurance: "I",
  Declined: "D",

  entries: [
    { value: "F", label: "Firm" },
    { value: "I", label: "Insurance" },
    { value: "D", label: "Declined (CoP offered at confirmation)" },
  ],

  values: ["F", "I", "D"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ConfirmationResponse> {
    return ConfirmationResponseEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ConfirmationResponse | null> {
    return [
      { value: null, label: blankLabel },
      ...ConfirmationResponseEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ConfirmationResponse {
    return (ConfirmationResponseEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ConfirmationResponseEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ClearingDecision = "U" | "R";

interface ClearingDecisionEnum extends Enum<ClearingDecision> {
  Unconditional: ClearingDecision;
  Rejected: ClearingDecision;
}

export const ClearingDecisionEnum: ClearingDecisionEnum = {
  name: "ClearingDecision",

  Unconditional: "U",
  Rejected: "R",

  entries: [
    { value: "U", label: "Unconditional" },
    { value: "R", label: "Rejected" },
  ],

  values: ["U", "R"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ClearingDecision> {
    return ClearingDecisionEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ClearingDecision | null> {
    return [
      { value: null, label: blankLabel },
      ...ClearingDecisionEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ClearingDecision {
    return (ClearingDecisionEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ClearingDecisionEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ClearingResponse = "F" | "D";

interface ClearingResponseEnum extends Enum<ClearingResponse> {
  Firm: ClearingResponse;
  Declined: ClearingResponse;
}

export const ClearingResponseEnum: ClearingResponseEnum = {
  name: "ClearingResponse",

  Firm: "F",
  Declined: "D",

  entries: [
    { value: "F", label: "Firm" },
    { value: "D", label: "Declined" },
  ],

  values: ["F", "D"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ClearingResponse> {
    return ClearingResponseEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ClearingResponse | null> {
    return [
      { value: null, label: blankLabel },
      ...ClearingResponseEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ClearingResponse {
    return (ClearingResponseEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ClearingResponseEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ApplicationStatus = "A-A" | "A-ATR" | "A-W" | "A-WE" | "A-WH" | "A-WR" | "A-RAT" | "A-RAD" | "A-RPL" | "A-ZC" | "A-ZM" | "A-ZD";

interface ApplicationStatusEnum extends Enum<ApplicationStatus> {
  Live: ApplicationStatus;
  Transferred: ApplicationStatus;
  Withdrawn: ApplicationStatus;
  OtherInstitution: ApplicationStatus;
  OtherProgramme: ApplicationStatus;
  Released: ApplicationStatus;
  ReverseTransfer: ApplicationStatus;
  Deferred: ApplicationStatus;
  Changing: ApplicationStatus;
  Cancelled: ApplicationStatus;
  Miscoded: ApplicationStatus;
  DeferredTemp: ApplicationStatus;
}

export const ApplicationStatusEnum: ApplicationStatusEnum = {
  name: "ApplicationStatus",

  Live: "A-A",
  Transferred: "A-ATR",
  Withdrawn: "A-W",
  OtherInstitution: "A-WE",
  OtherProgramme: "A-WH",
  Released: "A-WR",
  ReverseTransfer: "A-RAT",
  Deferred: "A-RAD",
  Changing: "A-RPL",
  Cancelled: "A-ZC",
  Miscoded: "A-ZM",
  DeferredTemp: "A-ZD",

  entries: [
    { value: "A-A", label: "Live (application ongoing)" },
    { value: "A-ATR", label: "Transferred to student records" },
    { value: "A-W", label: "Withdrawn" },
    { value: "A-WE", label: "Placed at another institution (UF elsewhere)" },
    { value: "A-WH", label: "Placed on another programme (UF here)" },
    { value: "A-WR", label: "Released into clearing" },
    { value: "A-RAT", label: "Reverse applicant transfer" },
    { value: "A-RAD", label: "Deferred to later year" },
    { value: "A-RPL", label: "Changing on UCAS" },
    { value: "A-ZC", label: "Cancelled (withdrawn)" },
    { value: "A-ZM", label: "Miscoded (withdrawn)" },
    { value: "A-ZD", label: "Deferred (dummy code)" },
  ],

  values: ["A-A", "A-ATR", "A-W", "A-WE", "A-WH", "A-WR", "A-RAT", "A-RAD", "A-RPL", "A-ZC", "A-ZM", "A-ZD"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ApplicationStatus> {
    return ApplicationStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ApplicationStatus | null> {
    return [
      { value: null, label: blankLabel },
      ...ApplicationStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ApplicationStatus {
    return (ApplicationStatusEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ApplicationStatusEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type OfferStatus = "M" | "P" | "S" | "O" | "A" | "C" | "L" | "I" | "H" | "D" | "R";

interface OfferStatusEnum extends Enum<OfferStatus> {
  Made: OfferStatus;
  MadePreRelease: OfferStatus;
  SelfReferral: OfferStatus;
  OnlineReferral: OfferStatus;
  Accepted: OfferStatus;
  Confirmed: OfferStatus;
  WaitingList: OfferStatus;
  Interview: OfferStatus;
  OnHold: OfferStatus;
  Declined: OfferStatus;
  Rejected: OfferStatus;
}

export const OfferStatusEnum: OfferStatusEnum = {
  name: "OfferStatus",

  Made: "M",
  MadePreRelease: "P",
  SelfReferral: "S",
  OnlineReferral: "O",
  Accepted: "A",
  Confirmed: "C",
  WaitingList: "L",
  Interview: "I",
  OnHold: "H",
  Declined: "D",
  Rejected: "R",

  entries: [
    { value: "M", label: "Standard offer made" },
    { value: "P", label: "Pre-release offer made" },
    { value: "S", label: "Self-referral" },
    { value: "O", label: "Online referral" },
    { value: "A", label: "Offer accepted" },
    { value: "C", label: "Offer confirmed" },
    { value: "L", label: "Waiting list" },
    { value: "I", label: "Interview" },
    { value: "H", label: "On hold" },
    { value: "D", label: "Applicant declined offer" },
    { value: "R", label: "School rejected applicant" },
  ],

  values: ["M", "P", "S", "O", "A", "C", "L", "I", "H", "D", "R"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<OfferStatus> {
    return OfferStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<OfferStatus | null> {
    return [
      { value: null, label: blankLabel },
      ...OfferStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is OfferStatus {
    return (OfferStatusEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return OfferStatusEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type OfferExpiry = "EOD" | "SOD" | "HOUR1" | "HOUR2" | "HOUR3" | "HOUR4" | "HOUR6" | "DAY1" | "DAY2" | "DAY3" | "DAY5" | "DAY7";

interface OfferExpiryEnum extends Enum<OfferExpiry> {
  EndOfDay: OfferExpiry;
  StartOfDay: OfferExpiry;
  OneHour: OfferExpiry;
  TwoHour: OfferExpiry;
  ThreeHour: OfferExpiry;
  FourHour: OfferExpiry;
  SixHour: OfferExpiry;
  OneDay: OfferExpiry;
  TwoDay: OfferExpiry;
  ThreeDay: OfferExpiry;
  FiveDay: OfferExpiry;
  SevenDay: OfferExpiry;
}

export const OfferExpiryEnum: OfferExpiryEnum = {
  name: "OfferExpiry",

  EndOfDay: "EOD",
  StartOfDay: "SOD",
  OneHour: "HOUR1",
  TwoHour: "HOUR2",
  ThreeHour: "HOUR3",
  FourHour: "HOUR4",
  SixHour: "HOUR6",
  OneDay: "DAY1",
  TwoDay: "DAY2",
  ThreeDay: "DAY3",
  FiveDay: "DAY5",
  SevenDay: "DAY7",

  entries: [
    { value: "EOD", label: "End of working day" },
    { value: "SOD", label: "Start of next working day" },
    { value: "HOUR1", label: "1 hour" },
    { value: "HOUR2", label: "2 hours" },
    { value: "HOUR3", label: "3 hours" },
    { value: "HOUR4", label: "4 hours" },
    { value: "HOUR6", label: "6 hours" },
    { value: "DAY1", label: "1 day" },
    { value: "DAY2", label: "2 days" },
    { value: "DAY3", label: "3 days" },
    { value: "DAY5", label: "5 days" },
    { value: "DAY7", label: "7 days" },
  ],

  values: ["EOD", "SOD", "HOUR1", "HOUR2", "HOUR3", "HOUR4", "HOUR6", "DAY1", "DAY2", "DAY3", "DAY5", "DAY7"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<OfferExpiry> {
    return OfferExpiryEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<OfferExpiry | null> {
    return [
      { value: null, label: blankLabel },
      ...OfferExpiryEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is OfferExpiry {
    return (OfferExpiryEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return OfferExpiryEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type RejectionReason = "G" | "S" | "Q" | "O" | "K" | "C" | "E" | "L" | "X";

interface RejectionReasonEnum extends Enum<RejectionReason> {
  GradesTooLow: RejectionReason;
  SubjectsIncorrect: RejectionReason;
  QualificationsNotAccepted: RejectionReason;
  ResultsOutstanding: RejectionReason;
  ScansNotReceived: RejectionReason;
  ClearingVacanciesFilled: RejectionReason;
  EarlyClearingVacanciesFilled: RejectionReason;
  WaitingListFilled: RejectionReason;
  ExpiredOffer: RejectionReason;
}

export const RejectionReasonEnum: RejectionReasonEnum = {
  name: "RejectionReason",

  GradesTooLow: "G",
  SubjectsIncorrect: "S",
  QualificationsNotAccepted: "Q",
  ResultsOutstanding: "O",
  ScansNotReceived: "K",
  ClearingVacanciesFilled: "C",
  EarlyClearingVacanciesFilled: "E",
  WaitingListFilled: "L",
  ExpiredOffer: "X",

  entries: [
    { value: "G", label: "Grades too low" },
    { value: "S", label: "Not taking correct subject(s)" },
    { value: "Q", label: "Qualification(s) not accepted" },
    { value: "O", label: "Final results still outstanding" },
    { value: "K", label: "Scans not received by deadline" },
    { value: "C", label: "Clearing vacancies filled" },
    { value: "E", label: "No vacancies in Early Clearing" },
    { value: "L", label: "Waitlist vacancies filled" },
    { value: "X", label: "Offer has expired" },
  ],

  values: ["G", "S", "Q", "O", "K", "C", "E", "L", "X"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<RejectionReason> {
    return RejectionReasonEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<RejectionReason | null> {
    return [
      { value: null, label: blankLabel },
      ...RejectionReasonEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is RejectionReason {
    return (RejectionReasonEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return RejectionReasonEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type OfferScriptType =
  | "clearingVerbalOfferScript"
  | "clearingPreReleaseVerbalOfferScript"
  | "clearingWaitingListScript"
  | "clearingInterviewScript"
  | "clearingDeclinedOrRejectedScript";

interface OfferScriptTypeEnum extends Enum<OfferScriptType> {
  ClearingOffer: OfferScriptType;
  ClearingPreReleaseOffer: OfferScriptType;
  ClearingWaitingList: OfferScriptType;
  ClearingInterview: OfferScriptType;
  ClearingDeclinedOrRejected: OfferScriptType;
}

export const OfferScriptTypeEnum: OfferScriptTypeEnum = {
  name: "OfferScriptType",

  ClearingOffer: "clearingVerbalOfferScript",
  ClearingPreReleaseOffer: "clearingPreReleaseVerbalOfferScript",
  ClearingWaitingList: "clearingWaitingListScript",
  ClearingInterview: "clearingInterviewScript",
  ClearingDeclinedOrRejected: "clearingDeclinedOrRejectedScript",

  entries: [
    { value: "clearingVerbalOfferScript", label: "Clearing offer" },
    { value: "clearingPreReleaseVerbalOfferScript", label: "Clearing pre-release offer" },
    { value: "clearingWaitingListScript", label: "Clearing waiting list" },
    { value: "clearingInterviewScript", label: "Clearing interview" },
    { value: "clearingDeclinedOrRejectedScript", label: "Clearing declined or rejected" },
  ],

  values: [
    "clearingVerbalOfferScript",
    "clearingPreReleaseVerbalOfferScript",
    "clearingWaitingListScript",
    "clearingInterviewScript",
    "clearingDeclinedOrRejectedScript",
  ],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<OfferScriptType> {
    return OfferScriptTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<OfferScriptType | null> {
    return [
      { value: null, label: blankLabel },
      ...OfferScriptTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is OfferScriptType {
    return (OfferScriptTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return OfferScriptTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type LabelType = "std" | "todo" | "fao";

interface LabelTypeEnum extends Enum<LabelType> {
  Standard: LabelType;
  Todo: LabelType;
  Fao: LabelType;
}

export const LabelTypeEnum: LabelTypeEnum = {
  name: "LabelType",

  Standard: "std",
  Todo: "todo",
  Fao: "fao",

  entries: [
    { value: "std", label: "Standard" },
    { value: "todo", label: "TODO" },
    { value: "fao", label: "FAO" },
  ],

  values: ["std", "todo", "fao"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<LabelType> {
    return LabelTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<LabelType | null> {
    return [
      { value: null, label: blankLabel },
      ...LabelTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is LabelType {
    return (LabelTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return LabelTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type EmailType =
  | "clearingVerbalOffer"
  | "clearingPreReleaseVerbalOffer"
  | "clearingWaitingList"
  | "clearingInterview"
  | "clearingRejected"
  | "programmeClosed";

interface EmailTypeEnum extends Enum<EmailType> {
  ClearingVerbalOffer: EmailType;
  ClearingPreReleaseVerbalOffer: EmailType;
  ClearingWaitingList: EmailType;
  ClearingInterview: EmailType;
  ClearingRejected: EmailType;
  ProgrammeClosed: EmailType;
}

export const EmailTypeEnum: EmailTypeEnum = {
  name: "EmailType",

  ClearingVerbalOffer: "clearingVerbalOffer",
  ClearingPreReleaseVerbalOffer: "clearingPreReleaseVerbalOffer",
  ClearingWaitingList: "clearingWaitingList",
  ClearingInterview: "clearingInterview",
  ClearingRejected: "clearingRejected",
  ProgrammeClosed: "programmeClosed",

  entries: [
    { value: "clearingVerbalOffer", label: "Clearing verbal offer" },
    { value: "clearingPreReleaseVerbalOffer", label: "Clearing pre-release verbal offer" },
    { value: "clearingWaitingList", label: "Clearing waiting list" },
    { value: "clearingInterview", label: "Clearing interview" },
    { value: "clearingRejected", label: "Clearing rejected" },
    { value: "programmeClosed", label: "Programme closed" },
  ],

  values: ["clearingVerbalOffer", "clearingPreReleaseVerbalOffer", "clearingWaitingList", "clearingInterview", "clearingRejected", "programmeClosed"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<EmailType> {
    return EmailTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<EmailType | null> {
    return [
      { value: null, label: blankLabel },
      ...EmailTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is EmailType {
    return (EmailTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return EmailTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type AttachmentType = "Grades" | "IELTS" | "Other";

interface AttachmentTypeEnum extends Enum<AttachmentType> {
  GradesTranscript: AttachmentType;
  IELTSCertificate: AttachmentType;
  Other: AttachmentType;
}

export const AttachmentTypeEnum: AttachmentTypeEnum = {
  name: "AttachmentType",

  GradesTranscript: "Grades",
  IELTSCertificate: "IELTS",
  Other: "Other",

  entries: [
    { value: "Grades", label: "Grades transcript" },
    { value: "IELTS", label: "IELTS certificate" },
    { value: "Other", label: "Other" },
  ],

  values: ["Grades", "IELTS", "Other"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<AttachmentType> {
    return AttachmentTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<AttachmentType | null> {
    return [
      { value: null, label: blankLabel },
      ...AttachmentTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is AttachmentType {
    return (AttachmentTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return AttachmentTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type PredictionGrouping = "group" | "school" | "programme";

interface PredictionGroupingEnum extends Enum<PredictionGrouping> {
  Group: PredictionGrouping;
  School: PredictionGrouping;
  Programme: PredictionGrouping;
}

export const PredictionGroupingEnum: PredictionGroupingEnum = {
  name: "PredictionGrouping",

  Group: "group",
  School: "school",
  Programme: "programme",

  entries: [
    { value: "group", label: "reporting group" },
    { value: "school", label: "school" },
    { value: "programme", label: "programme" },
  ],

  values: ["group", "school", "programme"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<PredictionGrouping> {
    return PredictionGroupingEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<PredictionGrouping | null> {
    return [
      { value: null, label: blankLabel },
      ...PredictionGroupingEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is PredictionGrouping {
    return (PredictionGroupingEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return PredictionGroupingEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type GlobalPermissionType =
  | "SuperUser"
  | "CanActAsOtherUsers"
  | "CanUpdateSchools"
  | "CanUpdateUsers"
  | "CanSyncSelectorList"
  | "CanCreateApplicants"
  | "CanUpdateApplicantDetails"
  | "CanUpdateQualifications"
  | "CanUpdateNonALevelQualifications"
  | "CanMakeHotlineClearingOffers"
  | "CanUpdateProgrammes"
  | "CanUpdateAdmissionsLabelPrototypes"
  | "CanUpdateAdmissionsLabels"
  | "CanAddAnnouncements"
  | "CanUpdateAdmissionsNotes"
  | "CanMakeInterSchoolChangesOfProgramme"
  | "CanUpdateAdmissionsSavedSearches"
  | "CanUpdateAdmissionsTargets"
  | "CanExportSPOData"
  | "CanExportTransferGroups"
  | "CanImportUcasClearingPlusApplicants"
  | "CanViewInternationalEquivsTable"
  | "CanViewContextualStatus";

interface GlobalPermissionTypeEnum extends Enum<GlobalPermissionType> {
  SuperUser: GlobalPermissionType;
  CanActAsOtherUsers: GlobalPermissionType;
  CanUpdateSchools: GlobalPermissionType;
  CanUpdateUsers: GlobalPermissionType;
  CanSyncSelectorList: GlobalPermissionType;
  CanCreateApplicants: GlobalPermissionType;
  CanUpdateApplicantDetails: GlobalPermissionType;
  CanUpdateQualifications: GlobalPermissionType;
  CanUpdateNonALevelQualifications: GlobalPermissionType;
  CanMakeHotlineClearingOffers: GlobalPermissionType;
  CanUpdateProgrammes: GlobalPermissionType;
  CanUpdateAdmissionsLabelPrototypes: GlobalPermissionType;
  CanUpdateAdmissionsLabels: GlobalPermissionType;
  CanAddAnnouncements: GlobalPermissionType;
  CanUpdateAdmissionsNotes: GlobalPermissionType;
  CanMakeInterSchoolChangesOfProgramme: GlobalPermissionType;
  CanUpdateAdmissionsSavedSearches: GlobalPermissionType;
  CanUpdateAdmissionsTargets: GlobalPermissionType;
  CanExportSPOData: GlobalPermissionType;
  CanExportTransferGroups: GlobalPermissionType;
  CanImportUcasClearingPlusApplicants: GlobalPermissionType;
  CanViewInternationalEquivsTable: GlobalPermissionType;
  CanViewContextualStatus: GlobalPermissionType;
}

export const GlobalPermissionTypeEnum: GlobalPermissionTypeEnum = {
  name: "GlobalPermissionType",

  SuperUser: "SuperUser",
  CanActAsOtherUsers: "CanActAsOtherUsers",
  CanUpdateSchools: "CanUpdateSchools",
  CanUpdateUsers: "CanUpdateUsers",
  CanSyncSelectorList: "CanSyncSelectorList",
  CanCreateApplicants: "CanCreateApplicants",
  CanUpdateApplicantDetails: "CanUpdateApplicantDetails",
  CanUpdateQualifications: "CanUpdateQualifications",
  CanUpdateNonALevelQualifications: "CanUpdateNonALevelQualifications",
  CanMakeHotlineClearingOffers: "CanMakeHotlineClearingOffers",
  CanUpdateProgrammes: "CanUpdateProgrammes",
  CanUpdateAdmissionsLabelPrototypes: "CanUpdateAdmissionsLabelPrototypes",
  CanUpdateAdmissionsLabels: "CanUpdateAdmissionsLabels",
  CanAddAnnouncements: "CanAddAnnouncements",
  CanUpdateAdmissionsNotes: "CanUpdateAdmissionsNotes",
  CanMakeInterSchoolChangesOfProgramme: "CanMakeInterSchoolChangesOfProgramme",
  CanUpdateAdmissionsSavedSearches: "CanUpdateAdmissionsSavedSearches",
  CanUpdateAdmissionsTargets: "CanUpdateAdmissionsTargets",
  CanExportSPOData: "CanExportSPOData",
  CanExportTransferGroups: "CanExportTransferGroups",
  CanImportUcasClearingPlusApplicants: "CanImportUcasClearingPlusApplicants",
  CanViewInternationalEquivsTable: "CanViewInternationalEquivsTable",
  CanViewContextualStatus: "CanViewContextualStatus",

  entries: [
    { value: "SuperUser", label: "Super user" },
    { value: "CanActAsOtherUsers", label: "Can act as other users" },
    { value: "CanUpdateSchools", label: "Can update schools" },
    { value: "CanUpdateUsers", label: "Can update users" },
    { value: "CanSyncSelectorList", label: "Can sync selector list" },
    { value: "CanCreateApplicants", label: "Can create applicants" },
    { value: "CanUpdateApplicantDetails", label: "Can update applicant details" },
    { value: "CanUpdateQualifications", label: "Can update qualifications" },
    { value: "CanUpdateNonALevelQualifications", label: "Can update non-A-Level qualifications" },
    { value: "CanMakeHotlineClearingOffers", label: "Can make hotline clearing offers on allowed programmes" },
    { value: "CanUpdateProgrammes", label: "Can update programmes" },
    { value: "CanUpdateAdmissionsLabelPrototypes", label: "Can administer admissions labels" },
    { value: "CanUpdateAdmissionsLabels", label: "Can update admissions labels on applicants" },
    { value: "CanAddAnnouncements", label: "Can add announcements" },
    { value: "CanUpdateAdmissionsNotes", label: "Can update other users' admissions notes on applicants" },
    { value: "CanMakeInterSchoolChangesOfProgramme", label: "Can make inter school changes of programme" },
    { value: "CanUpdateAdmissionsSavedSearches", label: "Can update admissions saved searches" },
    { value: "CanUpdateAdmissionsTargets", label: "Can update admissions reporting groups" },
    { value: "CanExportSPOData", label: "Can call the API to export data to the SPO database" },
    { value: "CanExportTransferGroups", label: "Can call the API to export CMS programme data" },
    { value: "CanImportUcasClearingPlusApplicants", label: "Can import UCAS Clearing Plus applicants" },
    { value: "CanViewInternationalEquivsTable", label: "Can view international equivalences tables" },
    { value: "CanViewContextualStatus", label: "Can view contextual status of applicants" },
  ],

  values: [
    "SuperUser",
    "CanActAsOtherUsers",
    "CanUpdateSchools",
    "CanUpdateUsers",
    "CanSyncSelectorList",
    "CanCreateApplicants",
    "CanUpdateApplicantDetails",
    "CanUpdateQualifications",
    "CanUpdateNonALevelQualifications",
    "CanMakeHotlineClearingOffers",
    "CanUpdateProgrammes",
    "CanUpdateAdmissionsLabelPrototypes",
    "CanUpdateAdmissionsLabels",
    "CanAddAnnouncements",
    "CanUpdateAdmissionsNotes",
    "CanMakeInterSchoolChangesOfProgramme",
    "CanUpdateAdmissionsSavedSearches",
    "CanUpdateAdmissionsTargets",
    "CanExportSPOData",
    "CanExportTransferGroups",
    "CanImportUcasClearingPlusApplicants",
    "CanViewInternationalEquivsTable",
    "CanViewContextualStatus",
  ],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<GlobalPermissionType> {
    return GlobalPermissionTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<GlobalPermissionType | null> {
    return [
      { value: null, label: blankLabel },
      ...GlobalPermissionTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is GlobalPermissionType {
    return (GlobalPermissionTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return GlobalPermissionTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type SchoolPermissionType =
  | "CanUpdateSchoolConfig"
  | "CanUpdateSchoolDeadlines"
  | "CanUpdateClassifierRules"
  | "CanUpdateTelephoneScripts"
  | "CanUpdateEmailTemplates"
  | "CanUpdateReportingGroups"
  | "CanUpdateSchoolLabelPrototypes"
  | "CanUpdateSchoolSavedSearches"
  | "CanPutOnClearingWaitingList"
  | "CanMakeClearingOffers"
  | "CanRejectClearingApplications"
  | "CanAcceptClearingApplications"
  | "CanInterviewApplications"
  | "CanUpdateUcasApplications"
  | "CanUpdateCocoStatuses"
  | "CanUpdateUcasSitsFields"
  | "CanUpdateSchoolLabels"
  | "CanUpdateSchoolNotes"
  | "CanMakeOffersOnClosedProgrammes"
  | "CanUpdatePredictionOverrides";

interface SchoolPermissionTypeEnum extends Enum<SchoolPermissionType> {
  CanUpdateSchoolConfig: SchoolPermissionType;
  CanUpdateSchoolDeadlines: SchoolPermissionType;
  CanUpdateClassifierRules: SchoolPermissionType;
  CanUpdateTelephoneScripts: SchoolPermissionType;
  CanUpdateEmailTemplates: SchoolPermissionType;
  CanUpdateReportingGroups: SchoolPermissionType;
  CanUpdateSchoolLabelPrototypes: SchoolPermissionType;
  CanUpdateSchoolSavedSearches: SchoolPermissionType;
  CanPutOnClearingWaitingList: SchoolPermissionType;
  CanMakeClearingOffers: SchoolPermissionType;
  CanRejectClearingApplications: SchoolPermissionType;
  CanAcceptClearingApplications: SchoolPermissionType;
  CanInterviewApplications: SchoolPermissionType;
  CanUpdateUcasApplications: SchoolPermissionType;
  CanUpdateCocoStatuses: SchoolPermissionType;
  CanUpdateUcasSitsFields: SchoolPermissionType;
  CanUpdateSchoolLabels: SchoolPermissionType;
  CanUpdateSchoolNotes: SchoolPermissionType;
  CanMakeOffersOnClosedProgrammes: SchoolPermissionType;
  CanUpdatePredictionOverrides: SchoolPermissionType;
}

export const SchoolPermissionTypeEnum: SchoolPermissionTypeEnum = {
  name: "SchoolPermissionType",

  CanUpdateSchoolConfig: "CanUpdateSchoolConfig",
  CanUpdateSchoolDeadlines: "CanUpdateSchoolDeadlines",
  CanUpdateClassifierRules: "CanUpdateClassifierRules",
  CanUpdateTelephoneScripts: "CanUpdateTelephoneScripts",
  CanUpdateEmailTemplates: "CanUpdateEmailTemplates",
  CanUpdateReportingGroups: "CanUpdateReportingGroups",
  CanUpdateSchoolLabelPrototypes: "CanUpdateSchoolLabelPrototypes",
  CanUpdateSchoolSavedSearches: "CanUpdateSchoolSavedSearches",
  CanPutOnClearingWaitingList: "CanPutOnClearingWaitingList",
  CanMakeClearingOffers: "CanMakeClearingOffers",
  CanRejectClearingApplications: "CanRejectClearingApplications",
  CanAcceptClearingApplications: "CanAcceptClearingApplications",
  CanInterviewApplications: "CanInterviewApplications",
  CanUpdateUcasApplications: "CanUpdateUcasApplications",
  CanUpdateCocoStatuses: "CanUpdateCocoStatuses",
  CanUpdateUcasSitsFields: "CanUpdateUcasSitsFields",
  CanUpdateSchoolLabels: "CanUpdateSchoolLabels",
  CanUpdateSchoolNotes: "CanUpdateSchoolNotes",
  CanMakeOffersOnClosedProgrammes: "CanMakeOffersOnClosedProgrammes",
  CanUpdatePredictionOverrides: "CanUpdatePredictionOverrides",

  entries: [
    { value: "CanUpdateSchoolConfig", label: "Can update school general settings" },
    { value: "CanUpdateSchoolDeadlines", label: "Can update school offer deadlines" },
    { value: "CanUpdateClassifierRules", label: "Can update school classifier rules" },
    { value: "CanUpdateTelephoneScripts", label: "Can update school phone scripts" },
    { value: "CanUpdateEmailTemplates", label: "Can update school email templates" },
    { value: "CanUpdateReportingGroups", label: "Can update reporting groups" },
    { value: "CanUpdateSchoolLabelPrototypes", label: "Can administer school labels" },
    { value: "CanUpdateSchoolSavedSearches", label: "Can update school saved searches" },
    { value: "CanPutOnClearingWaitingList", label: "Can place Clearing applicants on waiting lists (H and L offer status)" },
    { value: "CanMakeClearingOffers", label: "Can make Clearing offers (O, S, P, M, and D offer status)" },
    { value: "CanRejectClearingApplications", label: "Can reject Clearing applicants (R offer status)" },
    { value: "CanAcceptClearingApplications", label: "Can accept Clearing applicants (S, A, and C offer statuses)" },
    { value: "CanInterviewApplications", label: "Can invite to interview (I)" },
    { value: "CanUpdateUcasApplications", label: "Can create and update UCAS applications" },
    { value: "CanUpdateCocoStatuses", label: "Can update COCO statuses" },
    { value: "CanUpdateUcasSitsFields", label: "Can update UCAS/SITS fields" },
    { value: "CanUpdateSchoolLabels", label: "Can update school labels on applicants" },
    { value: "CanUpdateSchoolNotes", label: "Can update other user's school notes on applicants" },
    { value: "CanMakeOffersOnClosedProgrammes", label: "Can make offers on closed programmes" },
    { value: "CanUpdatePredictionOverrides", label: "Can override predictions on applications" },
  ],

  values: [
    "CanUpdateSchoolConfig",
    "CanUpdateSchoolDeadlines",
    "CanUpdateClassifierRules",
    "CanUpdateTelephoneScripts",
    "CanUpdateEmailTemplates",
    "CanUpdateReportingGroups",
    "CanUpdateSchoolLabelPrototypes",
    "CanUpdateSchoolSavedSearches",
    "CanPutOnClearingWaitingList",
    "CanMakeClearingOffers",
    "CanRejectClearingApplications",
    "CanAcceptClearingApplications",
    "CanInterviewApplications",
    "CanUpdateUcasApplications",
    "CanUpdateCocoStatuses",
    "CanUpdateUcasSitsFields",
    "CanUpdateSchoolLabels",
    "CanUpdateSchoolNotes",
    "CanMakeOffersOnClosedProgrammes",
    "CanUpdatePredictionOverrides",
  ],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<SchoolPermissionType> {
    return SchoolPermissionTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<SchoolPermissionType | null> {
    return [
      { value: null, label: blankLabel },
      ...SchoolPermissionTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is SchoolPermissionType {
    return (SchoolPermissionTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return SchoolPermissionTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ApplicationSearchKeyword =
  | "tracking"
  | "ucas"
  | "ucassuffix"
  | "studentid"
  | "studentidsuffix"
  | "name"
  | "email"
  | "country"
  | "domicile"
  | "visarequired"
  | "doublecounted"
  | "prog"
  | "school"
  | "programme"
  | "origin"
  | "method"
  | "fees"
  | "feestatus"
  | "entry"
  | "entrypoint"
  | "initialdecision"
  | "initialresponse"
  | "confirmationdecision"
  | "confirmationresponse"
  | "clearingdecision"
  | "clearingresponse"
  | "status"
  | "applicationstatus"
  | "offerstatus"
  | "offerexpired"
  | "cop"
  | "changeprogramme"
  | "hotline"
  | "label"
  | "std"
  | "todo"
  | "done"
  | "check"
  | "fao"
  | "team"
  | "note"
  | "notes"
  | "attachments"
  | "predict"
  | "hasoffer"
  | "hasoffers"
  | "override";

interface ApplicationSearchKeywordEnum extends Enum<ApplicationSearchKeyword> {
  Tracking: ApplicationSearchKeyword;
  Ucas: ApplicationSearchKeyword;
  UcasSuffix: ApplicationSearchKeyword;
  StudentId: ApplicationSearchKeyword;
  StudentIdSuffix: ApplicationSearchKeyword;
  Name: ApplicationSearchKeyword;
  Email: ApplicationSearchKeyword;
  Country: ApplicationSearchKeyword;
  Domicile: ApplicationSearchKeyword;
  VisaRequired: ApplicationSearchKeyword;
  DoubleCounted: ApplicationSearchKeyword;
  Prog: ApplicationSearchKeyword;
  School: ApplicationSearchKeyword;
  Programme: ApplicationSearchKeyword;
  Origin: ApplicationSearchKeyword;
  Method: ApplicationSearchKeyword;
  Fees: ApplicationSearchKeyword;
  FeeStatus: ApplicationSearchKeyword;
  Entry: ApplicationSearchKeyword;
  EntryPoint: ApplicationSearchKeyword;
  InitialDecision: ApplicationSearchKeyword;
  InitialResponse: ApplicationSearchKeyword;
  ConfirmationDecision: ApplicationSearchKeyword;
  ConfirmationResponse: ApplicationSearchKeyword;
  ClearingDecision: ApplicationSearchKeyword;
  ClearingResponse: ApplicationSearchKeyword;
  Status: ApplicationSearchKeyword;
  ApplicationStatus: ApplicationSearchKeyword;
  OfferStatus: ApplicationSearchKeyword;
  OfferExpired: ApplicationSearchKeyword;
  CoP: ApplicationSearchKeyword;
  ChangeProgramme: ApplicationSearchKeyword;
  Hotline: ApplicationSearchKeyword;
  Label: ApplicationSearchKeyword;
  Std: ApplicationSearchKeyword;
  Todo: ApplicationSearchKeyword;
  Done: ApplicationSearchKeyword;
  Check: ApplicationSearchKeyword;
  Fao: ApplicationSearchKeyword;
  Team: ApplicationSearchKeyword;
  Note: ApplicationSearchKeyword;
  Notes: ApplicationSearchKeyword;
  Attachments: ApplicationSearchKeyword;
  Predict: ApplicationSearchKeyword;
  HasOffer: ApplicationSearchKeyword;
  HasOffers: ApplicationSearchKeyword;
  PredictionOverride: ApplicationSearchKeyword;
}

export const ApplicationSearchKeywordEnum: ApplicationSearchKeywordEnum = {
  name: "ApplicationSearchKeyword",

  Tracking: "tracking",
  Ucas: "ucas",
  UcasSuffix: "ucassuffix",
  StudentId: "studentid",
  StudentIdSuffix: "studentidsuffix",
  Name: "name",
  Email: "email",
  Country: "country",
  Domicile: "domicile",
  VisaRequired: "visarequired",
  DoubleCounted: "doublecounted",
  Prog: "prog",
  School: "school",
  Programme: "programme",
  Origin: "origin",
  Method: "method",
  Fees: "fees",
  FeeStatus: "feestatus",
  Entry: "entry",
  EntryPoint: "entrypoint",
  InitialDecision: "initialdecision",
  InitialResponse: "initialresponse",
  ConfirmationDecision: "confirmationdecision",
  ConfirmationResponse: "confirmationresponse",
  ClearingDecision: "clearingdecision",
  ClearingResponse: "clearingresponse",
  Status: "status",
  ApplicationStatus: "applicationstatus",
  OfferStatus: "offerstatus",
  OfferExpired: "offerexpired",
  CoP: "cop",
  ChangeProgramme: "changeprogramme",
  Hotline: "hotline",
  Label: "label",
  Std: "std",
  Todo: "todo",
  Done: "done",
  Check: "check",
  Fao: "fao",
  Team: "team",
  Note: "note",
  Notes: "notes",
  Attachments: "attachments",
  Predict: "predict",
  HasOffer: "hasoffer",
  HasOffers: "hasoffers",
  PredictionOverride: "override",

  entries: [
    { value: "tracking", label: "Search by tracking number, e.g. 'tracking:qm12345'" },
    { value: "ucas", label: "Search by UCAS personal ID, e.g. 'ucas:12345'" },
    { value: "ucassuffix", label: "Search by UCAS personal ID suffix, e.g. 'ucassuffix:12345'" },
    { value: "studentid", label: "Search by student ID, e.g. 'studentid:12345'" },
    { value: "studentidsuffix", label: "Search by student ID suffix, e.g. 'studentidsuffix:12345'" },
    { value: "name", label: "Search by forenames or surname, e.g. 'name:dave'" },
    { value: "email", label: "Search by email address, e.g. 'email:dave@qmul'" },
    { value: "country", label: "Search by country of domicile (typically entered on portal), e.g. 'country:de'" },
    { value: "domicile", label: "Alias for 'country'" },
    {
      value: "visarequired",
      label: "Search by visa requirement (typically entered on portal), e.g. 'visarequired:y', 'visarequired:n', 'visarequired:u'",
    },
    {
      value: "doublecounted",
      label: "Search for applicants that have more than one application with non-zero prediction, e.g. 'doublecounted:true'",
    },
    { value: "prog", label: "Search by programme code, e.g. 'prog:c100'" },
    { value: "school", label: "Search by school, e.g. 'school:Geography'" },
    { value: "programme", label: "Search by programme code, e.g. 'programme:c100'" },
    { value: "origin", label: "Search by system application originated in, e.g. `origin:portal`, `origin:springboard` or `origin:sits`" },
    { value: "method", label: "Search by application method, e.g. 'method:c', 'method:a', or 'method:u'" },
    { value: "fees", label: "Search by fee status, e.g. 'fees:hhc'" },
    { value: "feestatus", label: "Search by fee status, e.g. 'feestatus:hhc'" },
    { value: "entry", label: "Search by entry point, e.g. 'entry:21'" },
    { value: "entrypoint", label: "Search by entry point, e.g. 'entrypoint:21'" },
    { value: "initialdecision", label: "Search by initial decision code, e.g. 'initialdecision:c'" },
    { value: "initialresponse", label: "Search by initial response code, e.g. 'initialresponse:f'" },
    { value: "confirmationdecision", label: "Search by confirmation decision code, e.g. 'confirmationdecision:u'" },
    { value: "confirmationresponse", label: "Search by confirmation response code, e.g. 'confirmationresponse:f'" },
    { value: "clearingdecision", label: "Search by clearing decision code, e.g. 'clearingdecision:u'" },
    { value: "clearingresponse", label: "Search by clearing response code, e.g. 'clearingresponse:f'" },
    { value: "status", label: "Search by status text, e.g. 'status:rejected'" },
    { value: "applicationstatus", label: "Search by application status, e.g. 'applicationstatus:a-a'" },
    { value: "offerstatus", label: "Search by Springboard offer status, e.g. 'offerstatus:m'" },
    { value: "offerexpired", label: "Search by whether the Springboard offer has expired, e.g. 'offerexpired:yes'" },
    { value: "cop", label: "Search by whether a change of programme is in progress, e.g. 'cop:yes'" },
    { value: "changeprogramme", label: "Search by whether a change of programme is in progress, e.g. 'changeprogrammme:yes'" },
    { value: "hotline", label: "Search by whether the application was created by a hotline user, e.g. 'hotline:yes'" },
    { value: "label", label: "Search by label, e.g. 'label:grades'" },
    { value: "std", label: "Search by standard label, e.g. 'std:grades'" },
    { value: "todo", label: "Search by incomplete TODO label, e.g. 'todo:grades'" },
    { value: "done", label: "Search by completed TODO label, e.g. 'done:grades'" },
    { value: "check", label: "Search by TODO label (whether complete or incomplete), e.g. 'check:grades'" },
    { value: "fao", label: "Search by FAO label, e.g. 'fao:alex'" },
    { value: "team", label: "Search by FAO label (synonym for 'fao'), e.g. 'team:alex'" },
    { value: "note", label: "Search by the text of notes, e.g. 'note:released'" },
    { value: "notes", label: "Search by the text of notes, e.g. 'notes:released'" },
    {
      value: "attachments",
      label:
        "'attachments:pending' will find applicants where a request for attachments has been sent, but no files have yet been uploaded.\n    'attachments:unapproved' will find applicants where attachments have been provided but they haven't yet been approved.",
    },
    { value: "predict", label: "Search by predictions, e.g. 'predict:yes', 'predict:no', 'predict:maybe' or 'predict:unknown'" },
    { value: "hasoffer", label: "Search by whether the applicant has any offers, e.g. 'hasoffer:yes'. Alias of 'hasoffers'." },
    { value: "hasoffers", label: "Search by whether the applicant has any offers, e.g. 'hasoffers:yes'. Alias of 'hasoffer'." },
    { value: "override", label: "Search by whether the application's prediction has been manually overridden, e.g. 'override:yes'" },
  ],

  values: [
    "tracking",
    "ucas",
    "ucassuffix",
    "studentid",
    "studentidsuffix",
    "name",
    "email",
    "country",
    "domicile",
    "visarequired",
    "doublecounted",
    "prog",
    "school",
    "programme",
    "origin",
    "method",
    "fees",
    "feestatus",
    "entry",
    "entrypoint",
    "initialdecision",
    "initialresponse",
    "confirmationdecision",
    "confirmationresponse",
    "clearingdecision",
    "clearingresponse",
    "status",
    "applicationstatus",
    "offerstatus",
    "offerexpired",
    "cop",
    "changeprogramme",
    "hotline",
    "label",
    "std",
    "todo",
    "done",
    "check",
    "fao",
    "team",
    "note",
    "notes",
    "attachments",
    "predict",
    "hasoffer",
    "hasoffers",
    "override",
  ],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ApplicationSearchKeyword> {
    return ApplicationSearchKeywordEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ApplicationSearchKeyword | null> {
    return [
      { value: null, label: blankLabel },
      ...ApplicationSearchKeywordEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ApplicationSearchKeyword {
    return (ApplicationSearchKeywordEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ApplicationSearchKeywordEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ApplicationLiveness = "L" | "S" | "F";

interface ApplicationLivenessEnum extends Enum<ApplicationLiveness> {
  Live: ApplicationLiveness;
  Successful: ApplicationLiveness;
  Failed: ApplicationLiveness;
}

export const ApplicationLivenessEnum: ApplicationLivenessEnum = {
  name: "ApplicationLiveness",

  Live: "L",
  Successful: "S",
  Failed: "F",

  entries: [
    { value: "L", label: "Live" },
    { value: "S", label: "Successful" },
    { value: "F", label: "Failed" },
  ],

  values: ["L", "S", "F"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ApplicationLiveness> {
    return ApplicationLivenessEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ApplicationLiveness | null> {
    return [
      { value: null, label: blankLabel },
      ...ApplicationLivenessEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ApplicationLiveness {
    return (ApplicationLivenessEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ApplicationLivenessEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type IgnoredOption = "exclude" | "include" | "only";

interface IgnoredOptionEnum extends Enum<IgnoredOption> {
  Exclude: IgnoredOption;
  Include: IgnoredOption;
  Only: IgnoredOption;
}

export const IgnoredOptionEnum: IgnoredOptionEnum = {
  name: "IgnoredOption",

  Exclude: "exclude",
  Include: "include",
  Only: "only",

  entries: [
    { value: "exclude", label: "Exclude" },
    { value: "include", label: "Include" },
    { value: "only", label: "Only" },
  ],

  values: ["exclude", "include", "only"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<IgnoredOption> {
    return IgnoredOptionEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<IgnoredOption | null> {
    return [
      { value: null, label: blankLabel },
      ...IgnoredOptionEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is IgnoredOption {
    return (IgnoredOptionEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return IgnoredOptionEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ReportingGroupType = "report" | "control";

interface ReportingGroupTypeEnum extends Enum<ReportingGroupType> {
  Reporting: ReportingGroupType;
  Control: ReportingGroupType;
}

export const ReportingGroupTypeEnum: ReportingGroupTypeEnum = {
  name: "ReportingGroupType",

  Reporting: "report",
  Control: "control",

  entries: [
    { value: "report", label: "Reporting" },
    { value: "control", label: "Control" },
  ],

  values: ["report", "control"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ReportingGroupType> {
    return ReportingGroupTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ReportingGroupType | null> {
    return [
      { value: null, label: blankLabel },
      ...ReportingGroupTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ReportingGroupType {
    return (ReportingGroupTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ReportingGroupTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ASGrade = "A" | "B" | "C" | "D" | "E" | "NU";

interface ASGradeEnum extends Enum<ASGrade> {
  A: ASGrade;
  B: ASGrade;
  C: ASGrade;
  D: ASGrade;
  E: ASGrade;
  NU: ASGrade;
}

export const ASGradeEnum: ASGradeEnum = {
  name: "ASGrade",

  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",
  NU: "NU",

  entries: [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
    { value: "NU", label: "NU" },
  ],

  values: ["A", "B", "C", "D", "E", "NU"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ASGrade> {
    return ASGradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ASGrade | null> {
    return [
      { value: null, label: blankLabel },
      ...ASGradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ASGrade {
    return (ASGradeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ASGradeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ExtendedProjectGrade = "A*" | "A" | "B" | "C" | "D" | "E";

interface ExtendedProjectGradeEnum extends Enum<ExtendedProjectGrade> {
  AStar: ExtendedProjectGrade;
  A: ExtendedProjectGrade;
  B: ExtendedProjectGrade;
  C: ExtendedProjectGrade;
  D: ExtendedProjectGrade;
  E: ExtendedProjectGrade;
}

export const ExtendedProjectGradeEnum: ExtendedProjectGradeEnum = {
  name: "ExtendedProjectGrade",

  AStar: "A*",
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",

  entries: [
    { value: "A*", label: "A*" },
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
  ],

  values: ["A*", "A", "B", "C", "D", "E"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ExtendedProjectGrade> {
    return ExtendedProjectGradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ExtendedProjectGrade | null> {
    return [
      { value: null, label: blankLabel },
      ...ExtendedProjectGradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ExtendedProjectGrade {
    return (ExtendedProjectGradeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ExtendedProjectGradeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type A2Grade = "A*" | "A" | "B" | "C" | "D" | "E" | "NU";

interface A2GradeEnum extends Enum<A2Grade> {
  AStar: A2Grade;
  A: A2Grade;
  B: A2Grade;
  C: A2Grade;
  D: A2Grade;
  E: A2Grade;
  NU: A2Grade;
}

export const A2GradeEnum: A2GradeEnum = {
  name: "A2Grade",

  AStar: "A*",
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",
  NU: "NU",

  entries: [
    { value: "A*", label: "A*" },
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
    { value: "NU", label: "NU" },
  ],

  values: ["A*", "A", "B", "C", "D", "E", "NU"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<A2Grade> {
    return A2GradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<A2Grade | null> {
    return [
      { value: null, label: blankLabel },
      ...A2GradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is A2Grade {
    return (A2GradeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return A2GradeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type IBGrade = "7" | "6" | "5" | "4" | "3" | "2" | "1" | "N";

interface IBGradeEnum extends Enum<IBGrade> {
  Seven: IBGrade;
  Six: IBGrade;
  Five: IBGrade;
  Four: IBGrade;
  Three: IBGrade;
  Two: IBGrade;
  One: IBGrade;
  N: IBGrade;
}

export const IBGradeEnum: IBGradeEnum = {
  name: "IBGrade",

  Seven: "7",
  Six: "6",
  Five: "5",
  Four: "4",
  Three: "3",
  Two: "2",
  One: "1",
  N: "N",

  entries: [
    { value: "7", label: "7" },
    { value: "6", label: "6" },
    { value: "5", label: "5" },
    { value: "4", label: "4" },
    { value: "3", label: "3" },
    { value: "2", label: "2" },
    { value: "1", label: "1" },
    { value: "N", label: "N" },
  ],

  values: ["7", "6", "5", "4", "3", "2", "1", "N"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<IBGrade> {
    return IBGradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<IBGrade | null> {
    return [
      { value: null, label: blankLabel },
      ...IBGradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is IBGrade {
    return (IBGradeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return IBGradeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type BtecGrade = "D*" | "D" | "M" | "P" | "N";

interface BtecGradeEnum extends Enum<BtecGrade> {
  DStar: BtecGrade;
  D: BtecGrade;
  M: BtecGrade;
  P: BtecGrade;
  N: BtecGrade;
}

export const BtecGradeEnum: BtecGradeEnum = {
  name: "BtecGrade",

  DStar: "D*",
  D: "D",
  M: "M",
  P: "P",
  N: "N",

  entries: [
    { value: "D*", label: "Distinction *" },
    { value: "D", label: "Distinction" },
    { value: "M", label: "Merit" },
    { value: "P", label: "Pass" },
    { value: "N", label: "Near Pass" },
  ],

  values: ["D*", "D", "M", "P", "N"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<BtecGrade> {
    return BtecGradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<BtecGrade | null> {
    return [
      { value: null, label: blankLabel },
      ...BtecGradeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is BtecGrade {
    return (BtecGradeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return BtecGradeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type QualificationBoard = "A2" | "IB" | "BtecRqf" | "BtecQcf" | "FB" | "GA" | "IHSN" | "IHSWB" | "IHSO" | "ID" | "PM" | "SB" | "USD" | "UCAT";

interface QualificationBoardEnum extends Enum<QualificationBoard> {
  ALevel: QualificationBoard;
  IB: QualificationBoard;
  BtecRqf: QualificationBoard;
  BtecQcf: QualificationBoard;
  FrenchBac: QualificationBoard;
  GermanAbitur: QualificationBoard;
  IndianHsNat: QualificationBoard;
  IndianHsWb: QualificationBoard;
  IndianHsOther: QualificationBoard;
  ItalianDiploma: QualificationBoard;
  PolishMatura: QualificationBoard;
  SpanishBac: QualificationBoard;
  UsDiploma: QualificationBoard;
  UCAT: QualificationBoard;
}

export const QualificationBoardEnum: QualificationBoardEnum = {
  name: "QualificationBoard",

  ALevel: "A2",
  IB: "IB",
  BtecRqf: "BtecRqf",
  BtecQcf: "BtecQcf",
  FrenchBac: "FB",
  GermanAbitur: "GA",
  IndianHsNat: "IHSN",
  IndianHsWb: "IHSWB",
  IndianHsOther: "IHSO",
  ItalianDiploma: "ID",
  PolishMatura: "PM",
  SpanishBac: "SB",
  UsDiploma: "USD",
  UCAT: "UCAT",

  entries: [
    { value: "A2", label: "A-Levels" },
    { value: "IB", label: "International Baccalaureate" },
    { value: "BtecRqf", label: "BTEC with A-Levels (reformed 2019)" },
    { value: "BtecQcf", label: "BTEC with A-Levels (unreformed 2016)" },
    { value: "FB", label: "France: Baccalaur\u00E9at G\u00E9n\u00E9ral" },
    { value: "GA", label: "Germany: (Abitur) Zeugnis der Allgemeinen Hochschulreife" },
    { value: "IHSN", label: "India: Indian High School (awarded by the National Board - CBSE/ISC)" },
    { value: "IHSWB", label: "India: Indian High School (awarded by West Bengal Council of Higher Education)" },
    { value: "IHSO", label: "India: Indian High School (awarded by other boards)" },
    { value: "ID", label: "Italy: (Diploma) Diploma di Esame di Stato" },
    { value: "PM", label: "Poland: (Matura) \u015Awiadectwo Dojrza\u0142o\u015Bci" },
    { value: "SB", label: "Spain: (Baccalaureate) T\u00EDtulo de Bachiller/Bachillerato" },
    { value: "USD", label: "USA: American High School Diploma and Advanced Placement Tests" },
    { value: "UCAT", label: "University Clinical Aptitude Test (UCAT)" },
  ],

  values: ["A2", "IB", "BtecRqf", "BtecQcf", "FB", "GA", "IHSN", "IHSWB", "IHSO", "ID", "PM", "SB", "USD", "UCAT"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<QualificationBoard> {
    return QualificationBoardEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<QualificationBoard | null> {
    return [
      { value: null, label: blankLabel },
      ...QualificationBoardEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is QualificationBoard {
    return (QualificationBoardEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return QualificationBoardEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type QualificationType =
  | "A2"
  | "AS"
  | "EPQ"
  | "IBOverall"
  | "IBHigher"
  | "IBStandard"
  | "BtecExtendedRqf"
  | "BtecNationalRqf"
  | "BtecSubsidiaryRqf"
  | "BtecExtendedQcf"
  | "BtecNationalQcf"
  | "BtecSubsidiaryQcf"
  | "FrenchBacOverall"
  | "FrenchBac"
  | "IndianHsNatOverall"
  | "IndianHsNat"
  | "IndianHsWbOverall"
  | "IndianHsWb"
  | "IndianHsOtherOverall"
  | "IndianHsOther"
  | "GermanAbiturOverall"
  | "GermanAbitur"
  | "PolishMatura"
  | "SpanishBacOverall"
  | "SpanishBac"
  | "ItalianDiplomaOverall"
  | "ItalianDiploma"
  | "UsDiplomaOverall"
  | "UsDiploma"
  | "UCATScore"
  | "UCATBand";

interface QualificationTypeEnum extends Enum<QualificationType> {
  A2: QualificationType;
  AS: QualificationType;
  EPQ: QualificationType;
  IBOverall: QualificationType;
  IBHigher: QualificationType;
  IBStandard: QualificationType;
  BtecExtendedRqf: QualificationType;
  BtecNationalRqf: QualificationType;
  BtecSubsidiaryRqf: QualificationType;
  BtecExtendedQcf: QualificationType;
  BtecNationalQcf: QualificationType;
  BtecSubsidiaryQcf: QualificationType;
  FrenchBacOverall: QualificationType;
  FrenchBac: QualificationType;
  IndianHsNatOverall: QualificationType;
  IndianHsNat: QualificationType;
  IndianHsWbOverall: QualificationType;
  IndianHsWb: QualificationType;
  IndianHsOtherOverall: QualificationType;
  IndianHsOther: QualificationType;
  GermanAbiturOverall: QualificationType;
  GermanAbitur: QualificationType;
  PolishMatura: QualificationType;
  SpanishBacOverall: QualificationType;
  SpanishBac: QualificationType;
  ItalianDiplomaOverall: QualificationType;
  ItalianDiploma: QualificationType;
  UsDiplomaOverall: QualificationType;
  UsDiploma: QualificationType;
  UCATScore: QualificationType;
  UCATBand: QualificationType;
}

export const QualificationTypeEnum: QualificationTypeEnum = {
  name: "QualificationType",

  A2: "A2",
  AS: "AS",
  EPQ: "EPQ",
  IBOverall: "IBOverall",
  IBHigher: "IBHigher",
  IBStandard: "IBStandard",
  BtecExtendedRqf: "BtecExtendedRqf",
  BtecNationalRqf: "BtecNationalRqf",
  BtecSubsidiaryRqf: "BtecSubsidiaryRqf",
  BtecExtendedQcf: "BtecExtendedQcf",
  BtecNationalQcf: "BtecNationalQcf",
  BtecSubsidiaryQcf: "BtecSubsidiaryQcf",
  FrenchBacOverall: "FrenchBacOverall",
  FrenchBac: "FrenchBac",
  IndianHsNatOverall: "IndianHsNatOverall",
  IndianHsNat: "IndianHsNat",
  IndianHsWbOverall: "IndianHsWbOverall",
  IndianHsWb: "IndianHsWb",
  IndianHsOtherOverall: "IndianHsOtherOverall",
  IndianHsOther: "IndianHsOther",
  GermanAbiturOverall: "GermanAbiturOverall",
  GermanAbitur: "GermanAbitur",
  PolishMatura: "PolishMatura",
  SpanishBacOverall: "SpanishBacOverall",
  SpanishBac: "SpanishBac",
  ItalianDiplomaOverall: "ItalianDiplomaOverall",
  ItalianDiploma: "ItalianDiploma",
  UsDiplomaOverall: "UsDiplomaOverall",
  UsDiploma: "UsDiploma",
  UCATScore: "UCATScore",
  UCATBand: "UCATBand",

  entries: [
    { value: "A2", label: "A2" },
    { value: "AS", label: "AS" },
    { value: "EPQ", label: "Extended Project Qualification (EPQ)" },
    { value: "IBOverall", label: "IB Overall" },
    { value: "IBHigher", label: "IB Higher Level" },
    { value: "IBStandard", label: "IB Standard Level" },
    { value: "BtecExtendedRqf", label: "BTEC Extended Diploma (reformed 2019)" },
    { value: "BtecNationalRqf", label: "BTEC National Diploma (reformed 2019)" },
    { value: "BtecSubsidiaryRqf", label: "BTEC Subsidiary Diploma (reformed 2019)" },
    { value: "BtecExtendedQcf", label: "BTEC Extended Diploma (unreformed 2016)" },
    { value: "BtecNationalQcf", label: "BTEC National Diploma (unreformed 2016)" },
    { value: "BtecSubsidiaryQcf", label: "BTEC Subsidiary Diploma (unreformed 2016)" },
    { value: "FrenchBacOverall", label: "France: Baccalaur\u00E9at G\u00E9n\u00E9ral Overall" },
    { value: "FrenchBac", label: "France: Baccalaur\u00E9at G\u00E9n\u00E9ral" },
    { value: "IndianHsNatOverall", label: "India: High School Overall (National Board CBSE/ISC)" },
    { value: "IndianHsNat", label: "India: High School Final Year (National Board CBSE/ISC)" },
    { value: "IndianHsWbOverall", label: "India: High School Overall (West Bengal Council of Higher Education)" },
    { value: "IndianHsWb", label: "India: High School Final Year (West Bengal Council of Higher Education)" },
    { value: "IndianHsOtherOverall", label: "India: High School Overall (Other Boards)" },
    { value: "IndianHsOther", label: "India: High School Final Year (Other Boards)" },
    { value: "GermanAbiturOverall", label: "Germany: (Abitur) Zeugnis der Allgemeinen Hochschulreife Overall Grade" },
    { value: "GermanAbitur", label: "Germany: (Abitur) Zeugnis der Allgemeinen Hochschulreife Higher Level" },
    { value: "PolishMatura", label: "Poland: (Matura) \u015Awiadectwo Dojrza\u0142o\u015Bci (Certificate of Maturity)" },
    { value: "SpanishBacOverall", label: "Spain: (Baccalaureate) T\u00EDtulo de Bachiller/Bachillerato Overall" },
    { value: "SpanishBac", label: "Spain: (Baccalaureate) T\u00EDtulo de Bachiller/Bachillerato Final Year Subject" },
    { value: "ItalianDiplomaOverall", label: "Italy: (Diploma) Diploma di Esame di Stato Overall" },
    { value: "ItalianDiploma", label: "Italy: (Diploma) Diploma di Esame di Stato Final Year Subject" },
    { value: "UsDiplomaOverall", label: "USA: American High School Diploma (Overall)" },
    { value: "UsDiploma", label: "USA: American Advanced Placement Test" },
    { value: "UCATScore", label: "UCAT Score (University Clinical Aptitude Test)" },
    { value: "UCATBand", label: "UCAT Band (University Clinical Aptitude Test)" },
  ],

  values: [
    "A2",
    "AS",
    "EPQ",
    "IBOverall",
    "IBHigher",
    "IBStandard",
    "BtecExtendedRqf",
    "BtecNationalRqf",
    "BtecSubsidiaryRqf",
    "BtecExtendedQcf",
    "BtecNationalQcf",
    "BtecSubsidiaryQcf",
    "FrenchBacOverall",
    "FrenchBac",
    "IndianHsNatOverall",
    "IndianHsNat",
    "IndianHsWbOverall",
    "IndianHsWb",
    "IndianHsOtherOverall",
    "IndianHsOther",
    "GermanAbiturOverall",
    "GermanAbitur",
    "PolishMatura",
    "SpanishBacOverall",
    "SpanishBac",
    "ItalianDiplomaOverall",
    "ItalianDiploma",
    "UsDiplomaOverall",
    "UsDiploma",
    "UCATScore",
    "UCATBand",
  ],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<QualificationType> {
    return QualificationTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<QualificationType | null> {
    return [
      { value: null, label: blankLabel },
      ...QualificationTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is QualificationType {
    return (QualificationTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return QualificationTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type ALevelEquivalence = "Rough" | "Strict";

interface ALevelEquivalenceEnum extends Enum<ALevelEquivalence> {
  Rough: ALevelEquivalence;
  Strict: ALevelEquivalence;
}

export const ALevelEquivalenceEnum: ALevelEquivalenceEnum = {
  name: "ALevelEquivalence",

  Rough: "Rough",
  Strict: "Strict",

  entries: [
    { value: "Rough", label: "Rough equivalence (ABC is the same as BBB)" },
    { value: "Strict", label: "Strict equivalence (ABC is not the same as BBB)" },
  ],

  values: ["Rough", "Strict"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<ALevelEquivalence> {
    return ALevelEquivalenceEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<ALevelEquivalence | null> {
    return [
      { value: null, label: blankLabel },
      ...ALevelEquivalenceEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is ALevelEquivalence {
    return (ALevelEquivalenceEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return ALevelEquivalenceEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type RequirementsCheckStatus = "Passed" | "Failed" | "Undecided";

interface RequirementsCheckStatusEnum extends Enum<RequirementsCheckStatus> {
  Passed: RequirementsCheckStatus;
  Failed: RequirementsCheckStatus;
  Undecided: RequirementsCheckStatus;
}

export const RequirementsCheckStatusEnum: RequirementsCheckStatusEnum = {
  name: "RequirementsCheckStatus",

  Passed: "Passed",
  Failed: "Failed",
  Undecided: "Undecided",

  entries: [
    { value: "Passed", label: "Passed" },
    { value: "Failed", label: "Failed" },
    { value: "Undecided", label: "Undecided" },
  ],

  values: ["Passed", "Failed", "Undecided"],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<RequirementsCheckStatus> {
    return RequirementsCheckStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<RequirementsCheckStatus | null> {
    return [
      { value: null, label: blankLabel },
      ...RequirementsCheckStatusEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is RequirementsCheckStatus {
    return (RequirementsCheckStatusEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return RequirementsCheckStatusEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};

export type AuditEventType =
  | "ApplicantCreated"
  | "UcasApplicantImported"
  | "ApplicantDetailsUpdated"
  | "QualificationsUpdated"
  | "ApplicationCreated"
  | "ApplicationUpdated"
  | "SelectorActionApplied"
  | "ProgrammeClosed"
  | "NoteCreated"
  | "NoteUpdated"
  | "NoteDeleted"
  | "LabelSelected"
  | "LabelDeselected"
  | "PhoneCallAttempted"
  | "AttachmentRequested"
  | "AttachmentApproved"
  | "AttachmentUnapproved"
  | "AttachmentDeleted"
  | "AttachmentUploadedToSITS";

interface AuditEventTypeEnum extends Enum<AuditEventType> {
  ApplicantCreated: AuditEventType;
  UcasApplicantImported: AuditEventType;
  ApplicantDetailsUpdated: AuditEventType;
  QualificationsUpdated: AuditEventType;
  ApplicationCreated: AuditEventType;
  ApplicationUpdated: AuditEventType;
  SelectorActionApplied: AuditEventType;
  ProgrammeClosed: AuditEventType;
  NoteCreated: AuditEventType;
  NoteUpdated: AuditEventType;
  NoteDeleted: AuditEventType;
  LabelSelected: AuditEventType;
  LabelDeselected: AuditEventType;
  PhoneCallAttempted: AuditEventType;
  AttachmentRequested: AuditEventType;
  AttachmentApproved: AuditEventType;
  AttachmentUnapproved: AuditEventType;
  AttachmentDeleted: AuditEventType;
  AttachmentUploadedToSITS: AuditEventType;
}

export const AuditEventTypeEnum: AuditEventTypeEnum = {
  name: "AuditEventType",

  ApplicantCreated: "ApplicantCreated",
  UcasApplicantImported: "UcasApplicantImported",
  ApplicantDetailsUpdated: "ApplicantDetailsUpdated",
  QualificationsUpdated: "QualificationsUpdated",
  ApplicationCreated: "ApplicationCreated",
  ApplicationUpdated: "ApplicationUpdated",
  SelectorActionApplied: "SelectorActionApplied",
  ProgrammeClosed: "ProgrammeClosed",
  NoteCreated: "NoteCreated",
  NoteUpdated: "NoteUpdated",
  NoteDeleted: "NoteDeleted",
  LabelSelected: "LabelSelected",
  LabelDeselected: "LabelDeselected",
  PhoneCallAttempted: "PhoneCallAttempted",
  AttachmentRequested: "AttachmentRequested",
  AttachmentApproved: "AttachmentApproved",
  AttachmentUnapproved: "AttachmentUnapproved",
  AttachmentDeleted: "AttachmentDeleted",
  AttachmentUploadedToSITS: "AttachmentUploadedToSITS",

  entries: [
    { value: "ApplicantCreated", label: "Applicant Created" },
    { value: "UcasApplicantImported", label: "Ucas Applicant Imported" },
    { value: "ApplicantDetailsUpdated", label: "Applicant Details Updated" },
    { value: "QualificationsUpdated", label: "Qualifications Updated" },
    { value: "ApplicationCreated", label: "Application Created" },
    { value: "ApplicationUpdated", label: "Application Updated" },
    { value: "SelectorActionApplied", label: "Selector Action Applied" },
    { value: "ProgrammeClosed", label: "Programme Closed" },
    { value: "NoteCreated", label: "Note Created" },
    { value: "NoteUpdated", label: "Note Updated" },
    { value: "NoteDeleted", label: "Note Deleted" },
    { value: "LabelSelected", label: "Label Selected" },
    { value: "LabelDeselected", label: "Label Deselected" },
    { value: "PhoneCallAttempted", label: "Phone Call Attempted" },
    { value: "AttachmentRequested", label: "Attachment Requested" },
    { value: "AttachmentApproved", label: "Attachment Approved" },
    { value: "AttachmentUnapproved", label: "Attachment Unapproved" },
    { value: "AttachmentDeleted", label: "Attachment Deleted" },
    { value: "AttachmentUploadedToSITS", label: "Attachment Uploaded to SITS" },
  ],

  values: [
    "ApplicantCreated",
    "UcasApplicantImported",
    "ApplicantDetailsUpdated",
    "QualificationsUpdated",
    "ApplicationCreated",
    "ApplicationUpdated",
    "SelectorActionApplied",
    "ProgrammeClosed",
    "NoteCreated",
    "NoteUpdated",
    "NoteDeleted",
    "LabelSelected",
    "LabelDeselected",
    "PhoneCallAttempted",
    "AttachmentRequested",
    "AttachmentApproved",
    "AttachmentUnapproved",
    "AttachmentDeleted",
    "AttachmentUploadedToSITS",
  ],

  dropdownOptions(showValues: boolean = true): SimpleDropdownOptions<AuditEventType> {
    return AuditEventTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label }));
  },

  dropdownOptionsWithBlank(showValues: boolean = true, blankLabel: string = "-- Blank --"): SimpleDropdownOptions<AuditEventType | null> {
    return [
      { value: null, label: blankLabel },
      ...AuditEventTypeEnum.entries.map(({ value, label }) => (showValues ? { value, label: value + " - " + label } : { value, label })),
    ];
  },

  isValue(value: string): value is AuditEventType {
    return (AuditEventTypeEnum.values as unknown[]).indexOf(value) >= 0;
  },

  labelOf(value: unknown): string | null {
    return AuditEventTypeEnum.entries.find(entry => entry.value === value)?.label ?? null;
  },
};
